.tt-hint {
  visibility: hidden;
}

.search-form.form, .nav-search-form {
  span.twitter-typeahead {
    background: white;
    position: relative;
    display: inherit !important;
    width: 100%;
    border-radius: 30px;
  }

  .tt-menu { /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    top: 35px !important;
    padding: 0 !important;
    max-height: 300px;
    overflow-y: auto;
    text-align: left;
    z-index: 10000 !important;

    .tt-suggestion, .empty-results {
      padding: 8px 20px;
      font-size: 16px;
      line-height: 24px;
    }

    .tt-suggestion {
      margin: 0;
    }

    .tt-suggestion:hover {
      cursor: pointer !important;
      background: #f7f7f7 !important;

    }

    .tt-suggestion.tt-cursor { /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
      color: #333;
      background-color: #f7f7f7;
    }

    .tt-suggestion p {
      margin: 0;
    }
  }
}