/******* old Colors *****/
/* Special breakpoint just for the PHP homepage (see top-nav.less) */
#change-password-form .forgot-below {
  display: block;
  margin-top: 4px;
}
#change-password-form .input-row.old_password {
  margin-bottom: 4px;
}
#change-password-form .input-row.password {
  height: 80px;
  padding-bottom: 0;
}
#change-password-form .input-row.general {
  height: 20px;
  margin-top: 30px;
}
#change-password-form .buttons a {
  margin-right: 10px;
}
@media (max-width: 340px) {
  #change-password-form .buttons a {
    margin-right: 0;
  }
}
#change-password-form .buttons .btn-primary {
  min-width: 182px;
}
#forgot-password-modal .subhead,
.forgot-password .subhead {
  margin-bottom: 30px;
}
#forgot-password-modal .input-row.email-address,
.forgot-password .input-row.email-address {
  padding-bottom: 9px;
}
#forgot-password-modal .input-row.general.has-error,
.forgot-password .input-row.general.has-error {
  margin-top: 20px;
}
#forgot-password-modal .email-changed,
.forgot-password .email-changed {
  display: block;
  margin: 9px 0 30px;
}
#forgot-password-modal .email-changed-text > p,
.forgot-password .email-changed-text > p {
  font-size: 14px;
}
#forgot-password-modal .buttons a,
.forgot-password .buttons a {
  margin-right: 10px;
}
#forgot-password-modal .buttons .btn-primary,
.forgot-password .buttons .btn-primary {
  min-width: 157px;
}
#edit-name-modal .input-row {
  margin-bottom: 14px;
}
#edit-name-modal .custom-dropdown {
  margin-bottom: 30px;
}
#edit-name-modal .buttons a {
  margin-right: 10px;
}
@media (max-width: 360px) {
  #edit-name-modal .buttons a {
    margin-right: 0;
  }
}
#edit-name-modal .buttons .btn-primary {
  min-width: 154px;
}
#change-email-form .input-row.email-address,
#change-email-form .input-row.password {
  margin-bottom: 14px;
}
#change-email-form .input-row.general {
  height: 20px;
  margin-top: 30px;
}
#change-email-form .buttons a {
  margin-right: 10px;
}
@media (max-width: 360px) {
  #change-email-form .buttons a {
    margin-right: 0;
  }
}
#change-email-form .buttons .close {
  min-width: 120px;
}
#change-email-form .buttons .btn-primary {
  min-width: 182px;
}
#school-info .modal-content {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}
#school-info .school-description,
#school-info .input-row {
  margin-bottom: 30px;
  padding: 0;
}
#school-info .school-description .floating,
#school-info .input-row .floating {
  height: 49px;
}
#school-info .school-description .floating span,
#school-info .input-row .floating span {
  z-index: unset;
}
#school-info .input-school .multi-school-name {
  display: inline-block;
  width: 89%;
}
@media (max-width: 480px) {
  #school-info .input-school .multi-school-name {
    width: 84%;
  }
}
#school-info .input-school .remove-input {
  display: inline-block;
  padding: 8px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 60000010;
}
#school-info .input-school .remove-input:hover {
  background-color: rgba(86, 114, 196, 0.1);
}
#school-info .input-school .remove-input .icon-cancel-light {
  pointer-events: none;
  border-radius: 40px;
  border: solid 1px;
  position: static;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  color: #878787;
  margin: 0;
  padding: 6px 4px 6px 5px;
  cursor: pointer;
}
#school-info .input-school .remove-input .icon-cancel-light:hover {
  background-color: transparent;
}
#school-info .twitter-typeahead {
  width: 100%;
  left: 0;
}
#school-info .tt-menu {
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  color: #464646;
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  top: 90% !important;
  border-radius: 4px;
}
#school-info .tt-menu .tt-dataset-schools {
  font-size: 16px;
}
#school-info .tt-menu .tt-dataset-schools .tt-suggestion {
  padding: 17px 12px;
}
#school-info .tt-menu .tt-dataset-schools .tt-suggestion:hover {
  cursor: pointer;
  background-color: rgba(86, 114, 196, 0.1);
}
#school-info .buttons .close {
  margin-right: 10px;
  width: 120px;
}
#school-info .buttons .school-submit-button {
  width: 154px;
}
.edit-billing-modal .cc-info {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .edit-billing-modal .cc-info {
    flex-wrap: wrap;
  }
}
@media (max-width: 600px) {
  .edit-billing-modal .cc-info .expiration-date {
    min-height: 110px;
  }
}
@media (max-width: 480px) {
  .edit-billing-modal .cc-info .expiration-date {
    min-height: 75px;
    width: 100%;
  }
}
.edit-billing-modal .cc-info .expiration-date .dropdowns {
  display: flex;
  width: 220px;
}
@media (max-width: 480px) {
  .edit-billing-modal .cc-info .expiration-date .dropdowns {
    width: 100%;
  }
}
.edit-billing-modal .cc-info .expiration-date .dropdowns .custom-dropdown {
  min-width: auto;
  width: 50%;
  max-width: 105px;
}
@media (max-width: 480px) {
  .edit-billing-modal .cc-info .expiration-date .dropdowns .custom-dropdown {
    max-width: none;
  }
}
.edit-billing-modal .cc-info .expiration-date .dropdowns .custom-dropdown:first-of-type {
  margin-right: 10px;
}
.edit-billing-modal .cc-info .cvc {
  max-width: 168px;
}
@media (max-width: 480px) {
  .edit-billing-modal .cc-info .cvc {
    max-width: none;
    width: 100%;
  }
}
.edit-billing-modal .cc-info .cvc label {
  height: 50px;
}
.edit-billing-modal .errormsg {
  margin-top: 20px;
  font-weight: bolder;
  color: #e73225;
  font-size: 14px;
  text-align: center;
}
.edit-billing-modal .input-row {
  margin-bottom: 14px;
}
@media (max-width: 480px) {
  .edit-billing-modal .input-row {
    margin-bottom: 0;
  }
}
.edit-billing-modal .input-row.has-error {
  margin-bottom: 0;
}
.edit-billing-modal .input-row.general {
  margin-top: 20px;
  min-height: 20px;
}
@media (max-width: 600px) {
  .edit-billing-modal .input-row {
    min-height: 75px;
    padding-bottom: 10px;
  }
}
.edit-billing-modal .buttons a {
  margin-right: 10px;
}
@media (max-width: 360px) {
  .edit-billing-modal .buttons a {
    margin-right: 0;
  }
}
#cancel-autorenew-modal.winback-v2 .screen.survey .survey-question,
.cancel-autorenew-modal.winback-v2 .screen.survey .survey-question {
  margin-bottom: 30px;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.radio,
.cancel-autorenew-modal.winback-v2 .screen.survey label.radio {
  padding: 10px 0;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.radio .radio,
.cancel-autorenew-modal.winback-v2 .screen.survey label.radio .radio {
  position: absolute;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.radio .label-text,
.cancel-autorenew-modal.winback-v2 .screen.survey label.radio .label-text {
  font-size: 16px;
  margin-left: 27px;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.more-details,
.cancel-autorenew-modal.winback-v2 .screen.survey label.more-details {
  padding-top: 15px;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.more-details textarea,
.cancel-autorenew-modal.winback-v2 .screen.survey label.more-details textarea {
  height: 200px;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.more-details textarea::placeholder,
.cancel-autorenew-modal.winback-v2 .screen.survey label.more-details textarea::placeholder {
  font-size: 14px;
  font-weight: lighter;
}
#cancel-autorenew-modal.winback-v2 .screen.survey label.more-details.hidden,
.cancel-autorenew-modal.winback-v2 .screen.survey label.more-details.hidden {
  display: none;
}
#cancel-autorenew-modal.winback-v2 .screen.survey .buttons,
.cancel-autorenew-modal.winback-v2 .screen.survey .buttons {
  margin-top: 15px;
  float: right;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox,
.cancel-autorenew-modal.winback-v2 .screen .offerbox {
  padding: 0;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head {
  margin-bottom: 20px;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head svg.dicon,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head svg.dicon {
  vertical-align: top;
  margin-top: -5px;
  width: 60px;
  height: 60px;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head h4,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-head h4 {
  margin: 0;
  color: #5672c4;
  display: inline-block;
  width: 85%;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-body .special-offer-button .btn,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-body .special-offer-button .btn {
  padding: 11px 55px;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-body .special-offer-button .btn.accept-offer,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .special-offer-body .special-offer-button .btn.accept-offer {
  float: right;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox .errormsg,
.cancel-autorenew-modal.winback-v2 .screen .offerbox .errormsg {
  margin-top: 20px;
  font-weight: bolder;
  color: #e73225;
  font-size: 14px;
  text-align: center;
}
#cancel-autorenew-modal.winback-v2 .screen .offerbox.refund .special-offer-head svg.dicon,
.cancel-autorenew-modal.winback-v2 .screen .offerbox.refund .special-offer-head svg.dicon {
  margin-top: 10px;
}
#cancel-autorenew-modal ul,
.cancel-autorenew-modal ul {
  margin-top: 6px;
  margin-bottom: 30px;
}
#cancel-autorenew-modal .disclaimer,
.cancel-autorenew-modal .disclaimer {
  margin: 20px 0 0;
  color: #888;
  font-style: italic;
}
#cancel-autorenew-modal .modal-title .centered,
.cancel-autorenew-modal .modal-title .centered {
  text-align: center;
}
#cancel-autorenew-modal .screen,
.cancel-autorenew-modal .screen {
  display: none;
}
#cancel-autorenew-modal .screen .btn,
.cancel-autorenew-modal .screen .btn {
  pointer-events: none;
}
#cancel-autorenew-modal .screen.open,
.cancel-autorenew-modal .screen.open {
  display: block;
}
#cancel-autorenew-modal .screen.open .btn,
.cancel-autorenew-modal .screen.open .btn {
  pointer-events: auto;
}
#cancel-autorenew-modal .screen .step,
.cancel-autorenew-modal .screen .step {
  font-size: 0.9em;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
}
#cancel-autorenew-modal .screen.survey li,
.cancel-autorenew-modal .screen.survey li {
  margin: 8px 0;
  padding: 4px;
  cursor: pointer;
}
#cancel-autorenew-modal .screen.survey li span,
.cancel-autorenew-modal .screen.survey li span {
  color: #5672c4;
}
#cancel-autorenew-modal .screen.survey li:hover,
.cancel-autorenew-modal .screen.survey li:hover {
  background: #f2f2f2;
}
@media (min-width: 1200px) {
  #cancel-autorenew-modal .screen.survey-new h3,
  .cancel-autorenew-modal .screen.survey-new h3 {
    font-size: 24px;
  }
}
#cancel-autorenew-modal .screen.offer-financing,
.cancel-autorenew-modal .screen.offer-financing {
  padding: 20px 0 10px;
  text-align: center;
}
#cancel-autorenew-modal .screen.offer-financing h3,
.cancel-autorenew-modal .screen.offer-financing h3 {
  text-align: center;
}
@media (min-width: 1200px) {
  #cancel-autorenew-modal .screen.offer-financing h3,
  .cancel-autorenew-modal .screen.offer-financing h3 {
    font-size: 24px;
  }
}
#cancel-autorenew-modal .screen.offer-financing .offer-container,
.cancel-autorenew-modal .screen.offer-financing .offer-container {
  border: 1px solid #DDD;
  border-radius: 6px;
  width: 300px;
  margin: 0 auto;
  padding: 20px 0;
}
#cancel-autorenew-modal .screen.offer-financing .offer-container h2,
.cancel-autorenew-modal .screen.offer-financing .offer-container h2 {
  margin: 0;
}
#cancel-autorenew-modal .screen.offer-financing .btn,
.cancel-autorenew-modal .screen.offer-financing .btn {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 220px;
}
#cancel-autorenew-modal .screen.offer-financing p,
.cancel-autorenew-modal .screen.offer-financing p {
  margin-top: 15px;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted {
  text-align: center;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .icon-ok-circled,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .icon-ok-circled {
  color: #00C4C9;
  font-size: 54px;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted h3,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted h3 {
  text-align: center;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details {
  width: 300px;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 360px) {
  #cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details,
  .cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details {
    width: 100%;
  }
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .icon-ok,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .icon-ok {
  color: #00C4C9;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet {
  display: flex;
  font-size: 20px;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet:not(:first-child),
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet:not(:first-child) {
  margin-top: 14px;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet p,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .annual-financing-plan-details .offer-bullet p {
  font-size: 20px;
}
#cancel-autorenew-modal .screen.annualFinancingOfferAccepted .btn,
.cancel-autorenew-modal .screen.annualFinancingOfferAccepted .btn {
  margin: 25px auto 0;
  width: 170px;
}
#cancel-autorenew-modal .screen.more-details textarea,
.cancel-autorenew-modal .screen.more-details textarea {
  margin-bottom: 10px;
  padding: 8px;
  box-sizing: border-box;
}
#cancel-autorenew-modal .screen .buttons,
.cancel-autorenew-modal .screen .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 430px;
  margin: 0 auto;
}
#cancel-autorenew-modal .screen .buttons .btn,
.cancel-autorenew-modal .screen .buttons .btn {
  margin-bottom: 10px;
}
#cancel-autorenew-modal .screen .buttons a,
.cancel-autorenew-modal .screen .buttons a {
  min-width: 243px;
}
#cancel-autorenew-modal .screen .offerbox,
.cancel-autorenew-modal .screen .offerbox {
  padding: 15px;
}
#cancel-autorenew-modal .screen .offerbox .special-offer-button,
.cancel-autorenew-modal .screen .offerbox .special-offer-button {
  margin-top: 30px;
}
@media (max-width: 360px) {
  #cancel-autorenew-modal ul,
  .cancel-autorenew-modal ul {
    margin-bottom: 10px;
  }
  #cancel-autorenew-modal .buttons,
  .cancel-autorenew-modal .buttons {
    flex-direction: column !important;
  }
}
@media (min-width: 851px) {
  .modal.large.upgrade-modal.open {
    width: 600px;
    margin-left: -295px;
  }
}
@media (max-width: 850px) {
  .modal.large.upgrade-modal.open {
    margin-left: -30%;
    width: unset;
  }
}
@media (max-width: 750px) {
  .modal.large.upgrade-modal.open {
    margin-left: -35%;
    width: 70%;
  }
}
.modal.large.upgrade-modal.open .modal-content {
  max-height: unset;
}
@media (min-width: 751px) {
  .modal.large.upgrade-modal.open .modal-content {
    max-height: 520px;
  }
}
@media (min-width: 851px) {
  .modal.large.upgrade-modal.open .modal-content {
    max-height: 560px;
    max-width: 600px;
    padding: 30px 30px 20px 30px;
  }
}
.modal.large.upgrade-modal.open .hardcoded-upgrade-notice {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
}
.modal.large.upgrade-modal.open .hardcoded-upgrade-notice .button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.large.upgrade-modal.open .hardcoded-upgrade-notice .button .btn-long {
  padding: 11px 44px;
}
@media (min-width: 851px) {
  .modal.large.upgrade-modal.open .hardcoded-upgrade-notice {
    max-height: 560px;
    max-width: 600px;
    padding: 30px 60px 20px 60px;
  }
}
@media (max-width: 1200px) {
  .modal.large.upgrade-modal.open .hardcoded-upgrade-notice p {
    font-size: 13px;
  }
}
#upgrade-modal .btn,
.upgrade-modal .btn {
  pointer-events: none;
}
#upgrade-modal.open .btn,
.upgrade-modal.open .btn {
  pointer-events: auto;
}
#upgrade-modal .steps,
.upgrade-modal .steps {
  margin-top: 15px;
  position: relative;
  min-height: 280px;
}
#upgrade-modal .steps h4,
.upgrade-modal .steps h4 {
  margin-bottom: 10px;
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 24px;
}
#upgrade-modal .plan-type,
.upgrade-modal .plan-type {
  display: inline-block;
  position: relative;
}
@media (min-width: 751px) {
  #upgrade-modal .plan-type,
  .upgrade-modal .plan-type {
    vertical-align: top;
  }
}
@media (max-width: 750px) {
  #upgrade-modal .plan-type,
  .upgrade-modal .plan-type {
    margin-right: 0;
    width: 100%;
  }
}
#upgrade-modal .plan-type .plan,
.upgrade-modal .plan-type .plan {
  display: inline-block;
  width: 100%;
  margin: 0 1%;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: left;
}
#upgrade-modal .plan-type .plan h5,
.upgrade-modal .plan-type .plan h5 {
  font-size: 23px;
  margin: 0 0 4px;
}
#upgrade-modal .plan-type .plan .options label.selected,
.upgrade-modal .plan-type .plan .options label.selected {
  background: #5672c4;
  border: 1px solid #5672c4;
  border-radius: 3px;
  color: #FFF;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}
#upgrade-modal .plan-type .plan .features,
.upgrade-modal .plan-type .plan .features {
  font-size: 14px;
}
#upgrade-modal .plan-type .plan .options,
.upgrade-modal .plan-type .plan .options {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #DDD;
  font-size: 13px;
}
#upgrade-modal .plan-type .plan .options label,
.upgrade-modal .plan-type .plan .options label {
  display: block;
  padding: 7px 5px;
  margin-bottom: 3px;
  cursor: pointer;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 50px;
}
#upgrade-modal .plan-type .plan .options label input,
.upgrade-modal .plan-type .plan .options label input {
  vertical-align: middle;
  margin-right: 10px;
}
#upgrade-modal .plan-type .plan .options label strong,
.upgrade-modal .plan-type .plan .options label strong {
  display: inline-block;
  float: right;
}
#upgrade-modal .plan-type .plan .options label .notice,
.upgrade-modal .plan-type .plan .options label .notice {
  font-style: italic;
  display: block;
  margin-left: 15px;
}
#upgrade-modal .plan-type .plan .options label .notice.price,
.upgrade-modal .plan-type .plan .options label .notice.price {
  text-align: right;
}
#upgrade-modal .plan-type .plan .options label .notice.hidden,
.upgrade-modal .plan-type .plan .options label .notice.hidden {
  display: none;
}
#upgrade-modal .plan-type .plan .options label.lifetime .notice,
.upgrade-modal .plan-type .plan .options label.lifetime .notice {
  text-align: left;
  margin-top: 3px;
  margin-left: 25px;
}
#upgrade-modal .plan-type .plan ul,
.upgrade-modal .plan-type .plan ul {
  padding-left: 30px;
  margin-top: 3px;
  font-size: 13px;
}
#upgrade-modal .confirm,
.upgrade-modal .confirm {
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  padding-left: 15px;
  margin-bottom: 0;
  margin-left: 5px;
  border-left: 1px dotted #DDD;
}
@media (max-width: 750px) {
  #upgrade-modal .confirm,
  .upgrade-modal .confirm {
    position: static;
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
    border-left: unset;
    margin-left: unset;
  }
  #upgrade-modal .confirm .changes.form,
  .upgrade-modal .confirm .changes.form {
    max-width: 320px;
    margin: auto;
  }
}
#upgrade-modal .confirm h5,
.upgrade-modal .confirm h5 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 8px;
}
#upgrade-modal .confirm .delayed-message p,
.upgrade-modal .confirm .delayed-message p,
#upgrade-modal .confirm .downgrade-message p,
.upgrade-modal .confirm .downgrade-message p {
  margin-bottom: 15px;
}
#upgrade-modal .confirm .delayed-message .plan-name,
.upgrade-modal .confirm .delayed-message .plan-name,
#upgrade-modal .confirm .downgrade-message .plan-name,
.upgrade-modal .confirm .downgrade-message .plan-name {
  font-weight: bold;
  margin-bottom: 15px;
}
#upgrade-modal .confirm .order-details .line-item,
.upgrade-modal .confirm .order-details .line-item {
  max-width: 300px;
  padding: 3px 0;
  margin: 0 auto;
}
#upgrade-modal .confirm .order-details .line-item.plan-name,
.upgrade-modal .confirm .order-details .line-item.plan-name {
  font-weight: bold;
}
#upgrade-modal .confirm .order-details .line-item span,
.upgrade-modal .confirm .order-details .line-item span {
  float: right;
}
#upgrade-modal .confirm .order-details .line-item.upgrade-discount,
.upgrade-modal .confirm .order-details .line-item.upgrade-discount {
  color: #e73225;
  padding-top: 0;
}
#upgrade-modal .confirm .order-details .line-item.total,
.upgrade-modal .confirm .order-details .line-item.total {
  border-top: 1px solid #000;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
#upgrade-modal .confirm .order-details .line-item .grandtotal,
.upgrade-modal .confirm .order-details .line-item .grandtotal {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}
#upgrade-modal .confirm .order-details .line-item .grandtotaltext,
.upgrade-modal .confirm .order-details .line-item .grandtotaltext {
  margin-right: auto;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}
#upgrade-modal .confirm .order-details .line-item .tax,
.upgrade-modal .confirm .order-details .line-item .tax {
  font-size: 14px;
  font-weight: lighter;
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#upgrade-modal .confirm .order-details .line-item.applied-balance,
.upgrade-modal .confirm .order-details .line-item.applied-balance {
  display: none;
  color: #e73225;
  padding-top: 0;
}
#upgrade-modal .confirm .order-details .line-item.applied-balance.show,
.upgrade-modal .confirm .order-details .line-item.applied-balance.show {
  display: block;
}
#upgrade-modal .confirm .order-details .credit-card,
.upgrade-modal .confirm .order-details .credit-card {
  font-size: 13px;
  color: #888;
  margin-top: 6px;
  text-align: center;
}
#upgrade-modal .confirm .order-details .credit-card a,
.upgrade-modal .confirm .order-details .credit-card a {
  margin-left: 6px;
  display: inline-block;
}
#upgrade-modal .confirm .button-holder,
.upgrade-modal .confirm .button-holder {
  text-align: center;
  margin-top: 10px;
}
#upgrade-modal .confirm .button-holder .cancel,
.upgrade-modal .confirm .button-holder .cancel {
  margin-top: 15px;
  font-size: 14px;
}
#upgrade-modal .confirm .button-holder .errormsg,
.upgrade-modal .confirm .button-holder .errormsg {
  margin-top: 10px;
}
#upgrade-modal .confirm .button-holder .compliance-text,
.upgrade-modal .confirm .button-holder .compliance-text {
  font-size: 12px;
  margin: 10px 0 0;
  font-style: italic;
}
#upgrade-modal .confirm .loading-overlay,
.upgrade-modal .confirm .loading-overlay {
  display: none;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(237, 237, 237, 0.85);
  z-index: 500;
}
#upgrade-modal .confirm .loading-overlay p,
.upgrade-modal .confirm .loading-overlay p {
  font-size: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin-bottom: 10px;
}
#upgrade-modal .confirm .loading-overlay .ajax-loader,
.upgrade-modal .confirm .loading-overlay .ajax-loader {
  position: absolute;
  top: 50%;
  height: 104px;
  width: 100%;
  background: transparent url(/themes/sky/i/worksheets/loading-balls.gif) no-repeat 50% 36%;
}
#upgrade-modal .confirm.has-overlay .loading-overlay,
.upgrade-modal .confirm.has-overlay .loading-overlay {
  display: block;
}
@media (max-width: 850px) {
  #upgrade-modal .confirm,
  .upgrade-modal .confirm {
    width: 200px;
  }
  #upgrade-modal .modal-content,
  .upgrade-modal .modal-content {
    padding: 20px;
  }
}
@media (max-width: 850px) and (max-width: 750px) {
  #upgrade-modal .confirm,
  .upgrade-modal .confirm {
    width: 100%;
  }
}
@media (max-width: 850px) and (max-width: 750px) {
  #upgrade-modal .plan-type,
  .upgrade-modal .plan-type {
    margin-right: 0;
  }
}
@media (max-width: 749px) {
  #upgrade-modal .plan-type .plan,
  .upgrade-modal .plan-type .plan {
    display: block;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
  #upgrade-modal .plan-type .plan.plus,
  .upgrade-modal .plan-type .plan.plus {
    margin-bottom: 10px;
  }
}
@media (max-width: 359px) {
  #upgrade-modal .confirm,
  .upgrade-modal .confirm {
    width: 100%;
    position: static;
    padding-left: 0;
    border-left: 0;
    border-bottom: 1px dotted #DDD;
    padding-bottom: 10px;
    margin-bottom: 10px;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    text-align: center;
  }
  #upgrade-modal .confirm h4,
  .upgrade-modal .confirm h4 {
    border-bottom: 0;
    padding-bottom: 0;
  }
  #upgrade-modal .confirm .line-item,
  .upgrade-modal .confirm .line-item {
    text-align: left;
  }
  #upgrade-modal .confirm .changes,
  .upgrade-modal .confirm .changes {
    padding: 5px;
  }
  #upgrade-modal .plan-type,
  .upgrade-modal .plan-type {
    margin-right: 0;
  }
}
#collections {
  pointer-events: none;
}
#collections h3 {
  font-size: 26px;
}
#collections i.btn-round {
  display: inline-block;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #878787;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 10;
  transition: 250ms color, 250ms background;
}
#collections i.btn-round.icon-down-open:before {
  vertical-align: 2px;
  font-size: 16px;
  transition: 250ms vertical-align, 250ms color;
}
#collections i.btn-round.icon-down-open:hover:before {
  vertical-align: -2px;
  color: #5672c4;
}
#collections i.btn-round:before {
  margin-left: 0;
  margin-right: 0;
  width: 0.9em;
}
#collections i.btn-round:hover {
  background-color: rgba(86, 114, 196, 0.1);
  color: #5672c4;
}
#collections .icon-cancel-light {
  position: absolute;
  top: 10px;
  right: 10px;
}
#collections .error {
  color: #e73225;
  height: auto;
  transition: height 250ms;
}
#collections .error:first-letter {
  text-transform: capitalize;
}
#collections .error.inactive {
  height: 0;
  opacity: 0;
}
#collections .overlay-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  pointer-events: none;
  cursor: pointer;
}
#collections .choose-collection {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 350px;
  height: 450px;
  top: 50vh;
  left: 50vw;
  margin-left: -175px;
  margin-top: -225px;
  box-sizing: border-box;
  padding: 40px 40px 20px 40px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  z-index: 100002;
  pointer-events: none;
}
@media (max-width: 320px) {
  #collections .choose-collection {
    width: calc(100% - 20px);
    margin-left: 10px;
    left: 0;
  }
}
@media (max-height: 420px) {
  #collections .choose-collection {
    height: calc(100vh - 20px);
    margin-top: 10px;
    top: 0;
  }
}
#collections .choose-collection .list {
  overflow: auto;
}
#collections .choose-collection .title {
  margin: 0 0 25px 0;
}
#collections .choose-collection .item.new {
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}
#collections .choose-collection .item {
  height: 50px;
  line-height: 50px;
  margin: 15px 0 0 0;
}
#collections .choose-collection .item .icon {
  vertical-align: top;
  text-align: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 24px;
  background-size: cover;
  background-position: 50% 0;
  box-shadow: 0 0 0 1px #EDEDED inset;
}
#collections .choose-collection .item .icon.empty {
  font-size: 12px;
  color: #999;
  background: #faf5ed;
}
#collections .choose-collection .item .label {
  vertical-align: top;
  display: inline-flex;
  width: calc(100% - 70px);
  height: 50px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  align-items: center;
  overflow: hidden;
}
#collections .choose-collection .item .label span {
  margin-top: 5px;
  max-height: 48px;
}
#collections .choose-collection .item:hover {
  cursor: pointer;
  color: #5672c4;
}
#collections .choose-collection .item:hover .label {
  text-decoration: underline;
}
#collections .new-collection {
  position: fixed;
  width: 350px;
  min-height: 250px;
  top: 50vh;
  left: 50vw;
  margin-left: -175px;
  margin-top: -125px;
  box-sizing: border-box;
  padding: 40px 40px 20px 40px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  z-index: 100003;
  pointer-events: none;
}
@media (max-width: 320px) {
  #collections .new-collection {
    width: calc(100% - 20px);
    margin-left: 10px;
    left: 0;
  }
}
@media (max-height: 220px) {
  #collections .new-collection {
    height: calc(100vh - 20px);
    margin-top: 10px;
    top: 0;
  }
}
#collections .new-collection .title {
  margin: 0 0 25px 0;
}
#collections .new-collection .actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 15px;
}
#collections .new-collection .actions button {
  width: 125px;
}
#collections .collection {
  position: fixed;
  width: 100%;
  height: 200px;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.23);
  background: #faf5ed;
  z-index: 100001;
  pointer-events: none;
}
#collections .collection .ribbon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms;
  cursor: pointer;
}
#collections .collection .ribbon .icon-up-open {
  display: inline-block;
  vertical-align: -6px;
  margin: 0 10px 0 0;
  transition: 250ms vertical-align, 250ms color;
}
#collections .collection .ribbon .title {
  font-size: 20px;
  line-height: 36px;
  vertical-align: top;
  margin: 0;
  display: inline-block;
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#collections .collection .ribbon .title a {
  text-decoration: none;
  pointer-events: none;
  color: #464646;
}
#collections .collection .ribbon .count {
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  background: #00C4C9;
  font-weight: 600;
  margin: 0 0 0 10px;
  transition: transform 250ms;
}
#collections .collection .ribbon .count.animate {
  transform: scale(1.15);
}
#collections .collection .ribbon .error {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #faf5ed;
  padding: 4px 30px 0 30px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.15);
}
#collections .collection .ribbon:hover .icon-up-open {
  vertical-align: -2px;
  color: #5672c4;
}
#collections .collection .ribbon .cancel {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin: 7px 15px 0 0;
  background-color: transparent;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  top: 0;
  transition: background 0.25s;
}
#collections .collection .ribbon .cancel .icon-cancel-light {
  top: 8px;
  left: 9px;
  color: #5672c4;
}
#collections .collection .ribbon .cancel:hover {
  background-color: rgba(86, 114, 196, 0.1);
}
#collections .collection .full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  padding: 10px 20px;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 250ms;
}
#collections .collection .full .icon-down-open {
  position: absolute;
  top: 10px;
  right: 10px;
}
#collections .collection .full .left {
  display: inline-block;
  vertical-align: top;
  width: 280px;
  margin: 10px 10px 0 30px;
}
#collections .collection .full .left .icon-left-open {
  margin: -5px 0 0 -5px;
}
#collections .collection .full .left .title {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100vw - 120px);
  max-width: 250px;
  margin: 0 0 10px 0;
}
#collections .collection .full .left .change {
  display: inline-block;
  vertical-align: top;
  line-height: 32px;
  margin: 0 -5px 0 -10px;
}
#collections .collection .full .right {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 350px);
  overflow: hidden;
  white-space: nowrap;
  -webkit-mask-image: linear-gradient(to left, transparent 0%, #000000 100px);
  mask-image: linear-gradient(to left, transparent 0%, #000000 100px);
}
#collections .collection .full .right .content-result.in-collection-tray {
  display: inline-block;
  vertical-align: top;
  width: 160px;
  height: 170px;
  min-width: auto;
  min-height: auto;
  animation-fill-mode: forwards;
}
#collections .collection .full .right .content-result.in-collection-tray .icon-trash-light {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #ffffff;
}
#collections .collection .full .right .content-result.in-collection-tray .icon-trash-light:before {
  width: 0.95em;
}
#collections .collection .full .right .content-result.in-collection-tray .icon-trash-light:hover {
  color: #5672c4;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link {
  width: 160px;
  height: 170px;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section {
  width: 160px;
  height: 170px;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section .image {
  height: 115px;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section .image img {
  min-height: 115px;
  background: #ffffff;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section .content-title {
  height: 55px;
  padding: 0 10px;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section .content-title .title {
  height: 28px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}
#collections .collection .full .right .content-result.in-collection-tray .content-result-link .front-section .content-type {
  bottom: 5px;
  left: 10px;
}
#collections .collection .full .right .content-result.in-collection-tray.added {
  animation: collection-content-result-expand 250ms;
}
@keyframes collection-content-result-expand {
  from {
    opacity: 0;
    width: 0px;
  }
  to {
    opacity: 1;
    width: 160px;
  }
}
#collections .collection .full .right .content-result.in-collection-tray.removed {
  animation: collection-content-result-contract 250ms;
}
#collections .collection .full .right .content-result.in-collection-tray.removed .icon-trash-light {
  display: none;
}
@keyframes collection-content-result-contract {
  from {
    opacity: 1;
    width: 160px;
  }
  to {
    opacity: 0;
    width: 0px;
  }
}
@media (max-width: 600px) {
  #collections .collection .full .right {
    display: none;
  }
  #collections .collection .full .left {
    width: 100%;
  }
  #collections .collection .full .left .title {
    max-width: initial;
  }
}
#collections .collection.minimized {
  height: 55px;
}
#collections .collection.minimized .ribbon {
  opacity: 1;
  pointer-events: auto;
}
#collections .collection.minimized .full {
  opacity: 0;
  pointer-events: none;
}
#collections.active:not(.hidden).page-choose-collection .overlay-back {
  opacity: 1;
  pointer-events: auto;
}
#collections.active:not(.hidden).page-choose-collection .choose-collection {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}
#collections.active:not(.hidden).page-new-collection .overlay-back {
  opacity: 1;
  pointer-events: auto;
}
#collections.active:not(.hidden).page-new-collection .new-collection {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}
#collections.active:not(.hidden).page-collection .collection {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}
#collections-featurewall .roly-collections {
  position: absolute;
  top: 82px;
  right: 20px;
  width: 246px;
  height: 216px;
  z-index: 60000009;
  pointer-events: none;
}
#collections-featurewall .modal-content {
  padding-right: 250px;
  min-height: 350px;
}
#collections-featurewall .modal-content .buttons {
  margin-top: 80px;
  text-align: left;
}
#collections-featurewall .modal-content .buttons .collections-featurewall-btn-signup {
  margin-right: 20px;
}
@media (max-width: 750px) {
  #collections-featurewall {
    width: 75%;
    margin-left: 12%;
    left: 0;
  }
  #collections-featurewall .icon-cancel {
    right: 25px;
  }
  #collections-featurewall .modal-content {
    width: calc(100% - 40px);
    margin-left: 20px;
    padding-right: 210px;
    left: 0;
  }
  #collections-featurewall .roly-collections {
    transform: scale(0.8);
    right: 10px;
  }
}
@media (max-width: 550px) {
  #collections-featurewall .modal-content {
    padding-right: 20px;
    min-height: 250px;
  }
  #collections-featurewall .roly-collections {
    display: none;
  }
}
#collections-onboarding-end {
  width: 650px;
}
#collections-onboarding-end .modal-content {
  padding: 0;
  text-align: center;
}
#collections-onboarding-end .modal-content .top {
  padding: 25px 50px 35px;
}
#collections-onboarding-end .modal-content .top .success {
  font-size: 14px;
  line-height: 1.25;
  color: #00C4C9;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  padding-bottom: 25px;
  border-bottom: 1px solid #EDEDED;
  max-width: 350px;
  margin: 0 auto 20px;
}
@media (min-width: 1000px) {
  #collections-onboarding-end .modal-content .top .success {
    line-height: 1.375;
  }
}
#collections-onboarding-end .modal-content .top .tip-title {
  text-align: center;
  color: #9f9f9f;
  margin-bottom: 10px;
}
#collections-onboarding-end .modal-content .top .tip-copy {
  font-size: 30px;
}
#collections-onboarding-end .modal-content .next-step {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  background-color: #00b5ba;
  color: #FFF;
  font-size: 20px;
  position: relative;
  text-align: right;
  padding: 25px 20px 50px 20px;
}
#collections-onboarding-end .modal-content .next-step .roly-wrap .roly {
  position: absolute;
  top: -20px;
  left: 52px;
}
#collections-onboarding-end .modal-content .next-step .actions-container .btn {
  color: #FFF;
  border: 1px solid #FFF;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
}
#collections-onboarding-end .modal-content .next-step .actions-container .btn:hover {
  background-color: transparent;
}
@media (max-width: 750px) {
  #collections-onboarding-end {
    width: auto;
  }
}
@media (max-width: 583px) {
  #collections-onboarding-end .modal-content .top .tip-copy {
    font-size: 18px;
  }
  #collections-onboarding-end .modal-content .next-step {
    font-size: 16px;
  }
  #collections-onboarding-end .modal-content .next-step .roly-wrap .roly {
    transform: scale(0.75);
    top: -20px;
    left: 30px;
  }
}
@media (max-width: 480px) {
  #collections-onboarding-end .modal-content .top {
    padding: 25px 20px 35px;
  }
  #collections-onboarding-end .modal-content .next-step .roly-wrap {
    padding-left: 85px;
  }
  #collections-onboarding-end .modal-content .next-step .roly-wrap .roly {
    position: absolute;
    top: -20px;
    left: -10px;
  }
}
@media (max-width: 360px) {
  #collections-onboarding-end .modal-content .next-step {
    text-align: center;
  }
  #collections-onboarding-end .modal-content .next-step .actions-container {
    margin-top: 20px;
  }
  #collections-onboarding-end .modal-content .next-step .actions-container .btn {
    width: 100%;
  }
}
#my-accounts-modal .accounts {
  max-height: 400px;
  overflow-y: auto;
}
#my-accounts-modal .accounts .account-container {
  position: relative;
  max-height: 400px;
}
#my-accounts-modal .accounts .account-container:hover {
  cursor: pointer;
  background-color: #eaeef7;
}
#my-accounts-modal .accounts .account-container.social-accounts .account:not(:last-child) {
  margin-bottom: 15px;
}
#my-accounts-modal .accounts .account-container.active {
  background-color: #eaeef7;
  pointer-events: none;
}
#my-accounts-modal .accounts .account-container.active .account-info .email {
  width: calc(100% - 100px);
}
@media (max-width: 480px) {
  #my-accounts-modal .accounts .account-container.active .account-info .email {
    width: 100%;
  }
}
#my-accounts-modal .accounts .account-container .logged-in {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}
@media (max-width: 480px) {
  #my-accounts-modal .accounts .account-container .logged-in {
    position: static;
    margin-left: 50px;
    margin-top: 10px;
  }
}
#my-accounts-modal .accounts .account-container .account-container-header {
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
}
#my-accounts-modal .accounts .account-container .account .email-icon {
  display: inline-block;
}
#my-accounts-modal .accounts .account-container .account .email-icon .user-icon {
  margin-bottom: -10px;
  margin-right: 7px;
}
#my-accounts-modal .accounts .account-container .account .email-icon .social-icon {
  margin: 0 10px -5px 5px;
}
#my-accounts-modal .accounts .account-container .account .account-info {
  display: inline-block;
  width: calc(100% - 60px);
}
#my-accounts-modal .accounts .account-container .account .account-info .email {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 480px) {
  #my-accounts-modal .accounts .account-container .account .account-info .email {
    font-size: 16px;
  }
}
#my-accounts-modal .accounts .account-container .account .account-info .premium-label {
  margin: 5px 0;
}
#my-accounts-modal .accounts .account-container .account .account-info .premium-label .icon-diamond-outline {
  font-size: 12px;
  margin-right: 3px;
  color: #00b5ba;
}
#my-accounts-modal .accounts .account-container .account .account-info .premium-label span {
  display: inline-block;
  background: #00b5ba;
  color: #fff;
  padding: 1px 4px;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-radius: 2px;
  vertical-align: middle;
  text-transform: uppercase;
}
#my-accounts-modal .accounts .account-container .account .account-info .basic-label span {
  display: inline-block;
  color: #3b3b3b;
  border: 2px solid #C5C5C5;
  padding: 1px 4px;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-radius: 4px;
  vertical-align: middle;
  text-transform: uppercase;
  margin: 5px 0;
}
.freetrial_modal .modal-content h3,
.freetrial_modal .modal-content h4 {
  line-height: 28px;
  text-align: center;
}
.freetrial_modal .img-celebration {
  text-align: center;
}
.freetrial_modal .btn-container {
  text-align: right;
  margin-top: 30px;
}
.freetrial_modal .btn-container .msg-no-commitment {
  margin-top: 5px;
}
.freetrial_modal .btn-container .close {
  margin-right: 15px;
}
@media (max-width: 480px) {
  .freetrial_modal .description {
    display: none;
  }
  .freetrial_modal .btn-container {
    text-align: center;
  }
  .freetrial_modal .btn-container .btn {
    width: 100%;
  }
  .freetrial_modal .btn-container .close {
    display: none;
  }
}
#deactivate-account-modal .cannot-restore-text {
  padding-bottom: 25px;
}
#deactivate-account-modal .general {
  padding-bottom: 15px;
  color: #e73225;
}
.edcom-google {
  cursor: pointer;
  background: #4285F4;
  display: inline-block;
  height: 40px;
  border-radius: 4px;
  width: 200px;
  float: right;
}
@media (max-width: 750px) {
  .edcom-google {
    width: 245px;
  }
}
@media (max-width: 600px) {
  .edcom-google {
    width: 100%;
  }
}
.edcom-google .google-button {
  background: white;
  width: 34px;
  height: 27px;
  margin: 2px;
  padding-top: 8px;
  display: inline-block;
}
.edcom-google .google-logo {
  background-image: url("https://cdn.education.com/files/static/reg-modal/google-logo.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  background-size: 18px;
  margin-left: 8px;
}
.edcom-google .google-text {
  color: white;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: top;
  margin-left: 8px;
  font-family: 'Roboto', sans-serif;
}
.edcom-google.choose {
  width: 200px;
  margin-left: 0;
  float: none;
}
.clearfloat {
  clear: both;
}
.buyflow-redirect-loading-overlay {
  position: fixed;
  z-index: 60000010;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 150px;
  background: #000;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: white;
  font-size: 45px;
}
#signup-form .modal-content form {
  margin-top: 18px;
}
#signup-form .modal-content form .type-grade-separator {
  border-top: 1px solid #C5C5C5;
  border-bottom: none;
  margin-bottom: 15px;
}
#signup-form .modal-content form .input-row .forgot {
  font-size: 13px;
}
#signup-form .modal-content form .input-row.has_error .forgot {
  font-size: 15px;
}
#signup-form .modal-content form .input-row label.inline {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 360px) {
  #signup-form .modal-content form .input-row label.inline {
    margin-right: 6px;
  }
}
#signup-form .modal-content form .input-row label.checkbox {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-size: 15px;
  line-height: 18px;
}
@media (max-width: 400px) {
  #signup-form .modal-content form .input-row label.checkbox {
    margin-right: 6px;
    font-size: 13px;
  }
}
#signup-form .modal-content form .input-row label.checkbox:last-child {
  margin-right: 0;
}
#signup-form .modal-content form .input-row label.checkbox input,
#signup-form .modal-content form .input-row label.checkbox span {
  vertical-align: middle;
  display: inline-block;
}
#signup-form .modal-content form .disclaimer {
  text-align: center;
  margin: 15px 0 5px;
  padding: 0;
}
#signup-form .modal-content form .disclaimer p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.25;
}
@media (min-width: 1000px) {
  #signup-form .modal-content form .disclaimer p {
    line-height: 1.375;
  }
}
#signup-form .modal-content .choose-account .accounts {
  max-height: 400px;
  overflow-y: auto;
  display: grid;
}
#signup-form .modal-content .choose-account .accounts .account-container {
  position: relative;
  max-height: 400px;
}
#signup-form .modal-content .choose-account .accounts .account-container:hover:not(.google-accounts):not(.facebook-accounts) {
  cursor: pointer;
  background-color: #eaeef7;
}
#signup-form .modal-content .choose-account .accounts .account-container.google-accounts,
#signup-form .modal-content .choose-account .accounts .account-container.facebook-accounts {
  order: 10000;
}
#signup-form .modal-content .choose-account .accounts .account-container.google-accounts.hidden,
#signup-form .modal-content .choose-account .accounts .account-container.facebook-accounts.hidden {
  display: none;
}
#signup-form .modal-content .choose-account .accounts .account-container .account-container-header {
  padding-left: 10px;
  font-weight: bold;
}
#signup-form .modal-content .choose-account .accounts .account-container .register-with-google,
#signup-form .modal-content .choose-account .accounts .account-container .register-with-facebook {
  width: calc(100% - 15px);
  max-width: none;
  position: absolute;
  bottom: 20px;
  left: 5px;
}
#signup-form .modal-content .choose-account .accounts .account-container .register-with-google .abcRioButton,
#signup-form .modal-content .choose-account .accounts .account-container .register-with-facebook .abcRioButton {
  max-width: none;
  margin-bottom: 0;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .email-icon {
  display: inline-block;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .email-icon .user-icon {
  margin-bottom: -10px;
  margin-right: 7px;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .email-icon .social-icon {
  margin: 0 10px -5px 5px;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info {
  display: inline-block;
  width: calc(100% - 60px);
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info .email {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 480px) {
  #signup-form .modal-content .choose-account .accounts .account-container .account .account-info .email {
    font-size: 16px;
  }
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info .premium-label {
  margin: 5px 0;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info .premium-label .icon-diamond-outline {
  font-size: 12px;
  margin-right: 3px;
  color: #00b5ba;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info .premium-label span {
  display: inline-block;
  background: #00b5ba;
  color: #fff;
  padding: 1px 4px;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-radius: 2px;
  vertical-align: middle;
  text-transform: uppercase;
}
#signup-form .modal-content .choose-account .accounts .account-container .account .account-info .basic-label span {
  display: inline-block;
  color: #3b3b3b;
  border: 2px solid #C5C5C5;
  padding: 1px 4px;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-radius: 4px;
  vertical-align: middle;
  text-transform: uppercase;
}
#signup-form .modal-content .sign-in .loginwith {
  margin-bottom: 20px;
}
#signup-form .modal-content .sign-in .switch-accounts {
  text-align: center;
}
#signup-form .modal-content .sign-in .switch-accounts .switch-accounts-link {
  font-size: 16px;
}
#signup-form .modal-content .sign-in .switch-accounts.hidden {
  display: none;
}
#signup-form .modal-content .create-account .signup-form .loginwith.smaller-social-btns #register-with-google,
#signup-form .modal-content .create-account .signup-form .loginwith.smaller-social-btns .register-with-google {
  max-width: none;
}
#signup-form .modal-content .create-account .signup-form .loginwith.smaller-social-btns #register-with-google .abcRioButton,
#signup-form .modal-content .create-account .signup-form .loginwith.smaller-social-btns .register-with-google .abcRioButton {
  max-width: none;
}
#signup-form .modal-content .or-area {
  text-align: center;
  border-bottom: 1px solid #C5C5C5;
  line-height: 0.1em;
  margin: 20px 0 30px;
}
#signup-form .modal-content .or-area span {
  background-color: #fff;
  padding: 0 20px;
  color: #C5C5C5;
}
#signup-form .modal-content .loginwith .abcRioButton {
  border: 1px solid #4285f4;
  border-radius: 3px;
  width: 100% !important;
}
#signup-form .modal-content .loginwith .abcRioButton .abcRioButtonIcon {
  display: inline-block;
  float: none;
}
#signup-form .modal-content .loginwith .abcRioButton .abcRioButtonContents {
  display: inline-block;
  font-size: 21px !important;
}
@media (max-width: 359px) {
  #signup-form .modal-content .loginwith .abcRioButton .abcRioButtonContents {
    font-size: 18px !important;
  }
}
#signup-form .modal-content .loginwith .abcRioButton.fb-btn-container {
  background-color: #3b5998;
  color: white;
}
#signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb_iframe_widget span {
  display: inline !important;
  padding: 15px;
  margin-right: 15px;
  width: 15px !important;
  height: 15px !important;
}
@media (max-width: 749px) {
  #signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb_iframe_widget span {
    display: inline-block !important;
    padding: 13px 17px 13px 21px;
    margin-right: 0;
  }
}
#signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb-login-button {
  display: inline-block;
}
#signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb-login-text {
  font-size: 21px;
  line-height: 45px;
}
@media (max-width: 749px) {
  #signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb-login-text {
    vertical-align: top;
  }
}
@media (max-width: 359px) {
  #signup-form .modal-content .loginwith .abcRioButton.fb-btn-container .fb-login-text {
    font-size: 18px !important;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton {
  border: 1px solid #4285f4;
  border-radius: 3px;
  width: 100%;
  height: 40px !important;
  box-sizing: border-box;
  max-width: 200px;
  vertical-align: top;
}
@media (max-width: 750px) {
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton {
    max-width: 245px;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton .abcRioButtonIcon {
  display: inline-block;
  float: none;
  padding: 8px 8px 10px 30px;
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton .abcRioButtonContents {
  display: inline-block;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-size: 15px !important;
  line-height: 40px;
}
@media (max-width: 359px) {
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton .abcRioButtonContents {
    font-size: 15px !important;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton.margin-right {
  margin-right: 10px;
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton.fb-btn-container .fb_iframe_widget span {
  display: inline-block !important;
  padding: 11px 6px;
  margin-right: 0;
}
@media (max-width: 749px) {
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton.fb-btn-container .fb_iframe_widget span {
    display: inline-block !important;
    padding: 10px 17px 13px 21px;
    margin-right: 0;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton.fb-btn-container .fb-login-text {
  font-size: 15px;
  line-height: 40px;
  vertical-align: top;
}
@media (max-width: 359px) {
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton.fb-btn-container .fb-login-text {
    font-size: 14px !important;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns #register-with-google,
#signup-form .modal-content .loginwith.smaller-social-btns .register-with-google {
  margin-bottom: 0;
  display: inline-block;
  max-width: 205px;
  width: 100%;
}
@media (max-width: 750px) {
  #signup-form .modal-content .loginwith.smaller-social-btns #register-with-google,
  #signup-form .modal-content .loginwith.smaller-social-btns .register-with-google {
    max-width: 245px;
  }
}
@media (max-width: 600px) {
  #signup-form .modal-content .loginwith.smaller-social-btns #register-with-google,
  #signup-form .modal-content .loginwith.smaller-social-btns .register-with-google {
    max-width: none;
  }
}
#signup-form .modal-content .loginwith.smaller-social-btns #register-with-google div.abcRioButtonIcon,
#signup-form .modal-content .loginwith.smaller-social-btns .register-with-google div.abcRioButtonIcon {
  background-color: white;
  padding: 5px 5px 4px 5px !important;
  vertical-align: -16px;
}
#signup-form .modal-content .loginwith.smaller-social-btns #register-with-google .abcRioButton,
#signup-form .modal-content .loginwith.smaller-social-btns .register-with-google .abcRioButton {
  background-color: #4285f4;
  color: white;
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton {
  border: 1px solid #4285f4;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 15px;
  height: 40px !important;
  box-sizing: border-box;
  max-width: 205px;
  vertical-align: top;
  text-align: left;
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton .abcRioButtonIcon {
  display: inline-block;
  float: none;
  padding: 4px !important;
  margin: 5px 8px;
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton .abcRioButtonContents {
  display: inline-block;
  font-size: 15px !important;
  line-height: 40px;
}
@media (max-width: 359px) {
  #signup-form .modal-content .loginwith.social-login-design .abcRioButton .abcRioButtonContents {
    font-size: 15px !important;
  }
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton.margin-right {
  margin-right: 0px;
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton.fb-btn-container {
  background-color: #3b5998;
  color: #FFF;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  max-width: none;
}
#signup-form .modal-content .loginwith.social-login-design .abcRioButton.fb-btn-container .fb_iframe_widget span {
  display: block;
  padding: 0px;
  margin-right: 0px;
  text-align: left !important;
}
@media (max-width: 749px) {
  #signup-form .modal-content .loginwith.social-login-design .abcRioButton.fb-btn-container .fb_iframe_widget span {
    display: block;
    /*padding: 10px 17px 13px 21px;*/
    margin-right: 0;
  }
}
#signup-form .modal-content .loginwith.social-login-design #register-with-google,
#signup-form .modal-content .loginwith.social-login-design .register-with-google {
  margin-bottom: 0;
  display: inline-block;
}
#signup-form .modal-content .loginwith.social-login-design .or-area {
  text-align: center;
  line-height: 0.1em;
  margin: 15px 0 30px;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  border-bottom: none;
  color: #464646;
}
#signup-form .modal-content .loginwith #status {
  color: #f05a3f;
  text-align: center;
}
#signup-form .modal-content .loginwith #register-with-google,
#signup-form .modal-content .loginwith .register-with-google {
  margin-bottom: 0px;
}
@media (max-width: 749px) {
  #signup-form .modal-content .create-account form {
    margin-top: 14px;
  }
  #signup-form .modal-content .create-account form .input-row {
    padding-bottom: 5px;
  }
  #signup-form .modal-content .create-account form .type-grade-separator {
    margin: 0 0 5px;
  }
  #signup-form .modal-content .create-account .loginwith .abcRioButton.fb-btn-container .fb-login-button-container {
    margin: 0 auto;
    text-align: left;
    width: 180px;
  }
  #signup-form .modal-content .create-account .loginwith .abcRioButton.fb-btn-container .fb-login-button-container .fb-login-button {
    display: inline-block;
  }
  #signup-form .modal-content .create-account .loginwith .or-area {
    margin-bottom: 0;
  }
  #signup-form .modal-content .create-account .loginwith .or-area.or-area-bottom {
    margin: 10px 0 20px;
  }
}
@media (max-width: 600px) {
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton {
    width: 100% !important;
    max-width: none !important;
    margin-bottom: 15px;
  }
  #signup-form .modal-content .loginwith.smaller-social-btns .abcRioButton .abcRioButtonIcon {
    padding: 12px 8px 10px 50px !important;
  }
  #signup-form .modal-content .loginwith.smaller-social-btns #register-with-google,
  #signup-form .modal-content .loginwith.smaller-social-btns .register-with-google {
    margin-bottom: 0;
    display: block;
  }
  #signup-form .modal-content .loginwith.social-login-design {
    text-align: center;
  }
  #signup-form .modal-content .loginwith.social-login-design .abcRioButton.margin-right {
    margin-right: 0px;
  }
  #signup-form .modal-content .loginwith.social-login-design .abcRioButton.fb-btn-container .fb_iframe_widget span {
    display: block;
    padding: 0px;
    margin-right: 0px;
    text-align: left !important;
  }
  #signup-form .modal-content .loginwith.social-login-design #register-with-google,
  #signup-form .modal-content .loginwith.social-login-design .register-with-google {
    margin-bottom: 0px;
    display: inline-block;
    max-width: 236px;
  }
}
@media (max-width: 480px) {
  #signup-form .modal-content {
    padding: 20px;
  }
  #signup-form .modal-content .create-account .description {
    margin: 0 0 5px;
  }
  #signup-form .modal-content .create-account form {
    margin-top: 10px;
  }
  #signup-form .modal-content .create-account form label.checkbox {
    margin: 0;
  }
  #signup-form .modal-content .create-account form .input-row.iama,
  #signup-form .modal-content .create-account form .input-row.grades {
    padding-bottom: 0;
  }
  #signup-form .modal-content .create-account .loginwith .abcRioButton.fb-btn-container {
    margin-bottom: 10px;
  }
  #signup-form .modal-content .create-account .loginwith #register-with-google,
  #signup-form .modal-content .create-account .loginwith .register-with-google {
    margin-bottom: 0;
  }
  #signup-form .modal-content .create-account .submit {
    width: 100% !important;
  }
}
#signup-form .signup-form.with-age-gate .description {
  margin: 10px 0;
}
#signup-form .signup-form.hidden {
  display: none;
}
#signup-form .signup-age-gate h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}
#signup-form .signup-age-gate .description {
  margin: 10px auto 20px;
  font-size: 16px;
}
#signup-form .signup-age-gate .btn-area {
  color: #464646;
  position: relative;
}
#signup-form .signup-age-gate .btn-area p {
  margin-bottom: 20px;
}
#signup-form .signup-age-gate .btn-area .text-bigger {
  font-size: 25px;
  color: #464646;
  padding: 10px;
}
#signup-form .signup-age-gate .btn-area button {
  margin: 15px 10px;
}
#signup-form .signup-age-gate .btn-area button.age-gate-pass {
  padding-left: 29px;
  padding-right: 29px;
}
#signup-form .signup-age-gate .btn-area button.age-gate-fail {
  padding-left: 44px;
  padding-right: 44px;
}
#signup-form .signup-age-gate .btn-area button:focus {
  outline: 0;
}
#signup-form .signup-age-gate .roly-kid {
  display: flex;
  margin: 0 auto -60px;
}
@media (max-width: 522px) {
  #signup-form .signup-age-gate {
    margin-bottom: 20px;
  }
  #signup-form .signup-age-gate .btn-area button.age-gate-pass {
    display: block;
    width: 95%;
    margin-right: 20px;
    order: 1;
  }
  #signup-form .signup-age-gate .btn-area button.age-gate-fail {
    display: block;
    width: 95%;
    margin-right: 20px;
    order: 2;
  }
  #signup-form .signup-age-gate .roly-kid {
    display: none;
  }
}
#signup-form .create-account .date-of-birth-age-gate-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 750px) {
  #signup-form .create-account .date-of-birth-age-gate-container {
    margin-bottom: 0;
  }
}
#signup-form .create-account .date-of-birth-age-gate-container .age-gate-title {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: -5px;
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container {
  display: flex;
  flex-direction: column;
}
@media (max-width: 750px) {
  #signup-form .create-account .date-of-birth-age-gate-container .user-type-container {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type-name {
  font-size: 20px;
  font-weight: 600;
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type {
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  padding: 19px;
  width: 100%;
  transition: all 250ms;
}
@media (max-width: 750px) {
  #signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type {
    padding: 20px;
    width: 100%;
  }
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type:last-of-type {
  margin-right: 0;
}
@media (max-width: 750px) {
  #signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type:last-of-type {
    margin-bottom: 0;
  }
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type:hover {
  background-color: #00C4C9;
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type:hover div,
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type:hover p {
  color: white;
}
#signup-form .create-account .date-of-birth-age-gate-container .user-type-container .user-type div {
  text-align: center;
  user-select: none;
  width: 100%;
}
#signup-form .create-account .screen-age-gate-enter-age {
  display: flex;
  flex-wrap: wrap;
}
#signup-form .create-account .screen-age-gate-enter-age h4 {
  font-size: 30px;
  margin-bottom: 20px;
  width: 100%;
}
@media (max-width: 750px) {
  #signup-form .create-account .screen-age-gate-enter-age h4 {
    font-size: 20px;
  }
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth {
  width: 100%;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-fields {
  align-content: space-between;
  display: flex;
  width: 100%;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-fields .input-row {
  margin-right: 15px;
  width: 33%;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-fields .input-row:last-of-type {
  margin-right: 0;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-fields .input-row .month-of-birth {
  min-width: 90px;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-birth-button {
  margin: 10px auto 10px;
  max-width: 300px;
}
#signup-form .create-account .screen-age-gate-enter-age .enter-date-birth .date-birth-error-message {
  color: #e73225;
  margin: 10px auto 20px;
  text-align: center;
}
#signup-form .create-account .screen-age-gate-enter-age .roly-kid {
  margin: 0 auto -60px;
}
#signup-form .create-account.hidden,
#signup-form .create-account .hidden {
  display: none !important;
}
#signup-form .create-account .screen-age-gate-enter-age-fail .age-gate-fail-instructions {
  text-align: center;
}
#signup-form .create-account .screen-age-gate-enter-age-fail .age-gate-fail-instructions div {
  font-size: 30px;
  margin-top: 20px;
}
#signup-form .screen-age-gate-fail {
  display: none;
  margin-bottom: 75px;
  position: relative;
  text-align: left;
}
#signup-form .screen-age-gate-fail .text-bigger {
  color: #464646;
  font-size: 30px;
}
#signup-form .screen-age-gate-fail .link-outer {
  margin-top: 50px;
  text-align: left;
  font-size: 25px;
}
#signup-form .screen-age-gate-fail .tutu-pre-teen {
  width: 146px;
  height: 185px;
}
@media (max-width: 530px) {
  #signup-form .screen-age-gate-fail .link-outer {
    font-size: 20px;
  }
  #signup-form .screen-age-gate-fail .tutu-pre-teen {
    width: 33%;
    height: auto;
  }
}
#signup-form .floyd-hand {
  position: absolute;
  top: -1%;
  right: 2%;
  z-index: 60000009;
}
@media (max-width: 820px) {
  #signup-form .floyd-hand {
    display: none;
  }
}
#signup-form .floyd-body {
  position: absolute;
  top: -8%;
  right: -29%;
  z-index: 60000001;
}
@media (max-width: 820px) {
  #signup-form .floyd-body {
    display: none;
  }
}
#additional-info-request form .btn-container {
  text-align: right;
  margin-top: 15px;
}
#additional-info-request form .btn {
  margin-left: 20px;
}
#additional-info-request form label.checkbox {
  margin-right: 10px;
}
#additional-info-request .twitter-typeahead {
  width: 100%;
  display: block;
}
#additional-info-request .tt-menu {
  background-color: white;
  border: 1px solid #464646;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #464646;
  height: 80px;
  margin: 2px 0 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 8px;
  width: 100%;
}
#additional-info-request .tt-menu .tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #5672c4;
}
body.with-ad-gutter .page-width {
  position: relative;
}
@media (max-width: 1623px) {
  body.with-ad-gutter .page-width {
    left: -135px;
  }
}
@media (max-width: 1373px) {
  body.with-ad-gutter .page-width {
    left: -66px;
  }
}
@media (max-width: 1233px) {
  body.with-ad-gutter .page-width {
    left: 0;
  }
}
body.with-ad-gutter .WorksheetContentDetail .page-width {
  padding-right: 105px;
}
.top-with-ad {
  text-align: center;
  padding-top: 35px;
}
.top-with-ad .ad-contents {
  display: inline-block;
}
.top-with-ad .ad-contents .ad {
  padding: 0;
}
.ad {
  padding: 5px 4px;
  border: 1px solid #ddd;
  position: relative;
}
@media (max-width: 320px) {
  .ad {
    border: 0;
    padding: 0;
  }
}
.ad:after {
  display: none;
  content: 'ADVERTISEMENT';
  font-size: 9px;
  color: #464646;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px;
  background: rgba(221, 221, 221, 0.8);
}
@media print {
  .ad {
    display: none !important;
  }
}
.ads-gutter-holder {
  width: 100%;
  max-width: 1624px;
  height: 0;
  margin: 0 auto;
  padding-left: 30px;
  position: relative;
  box-sizing: border-box;
}
.ads-gutter-holder.full-width-resources {
  max-width: 2100px;
}
@media (max-width: 1623px) {
  .ads-gutter-holder.full-width-resources {
    max-width: none;
  }
}
.ads-gutter-holder .ads-gutter {
  box-sizing: border-box;
  float: right;
  text-align: left;
  width: 312px;
  overflow: hidden;
  position: absolute;
  right: 0;
  height: 1200px;
}
.ads-gutter-holder .ads-gutter.full-width-resources {
  margin-top: 613px;
}
.ads-gutter-holder .ads-gutter .gutter-top {
  position: absolute;
  top: 0;
  height: 1100px;
}
.ads-gutter-holder .ads-gutter .gutter-bottom {
  position: absolute;
  top: 1200px;
  height: 1200px;
}
.ads-gutter-holder .ads-gutter .gutter-ad-top {
  position: absolute;
  top: 0;
}
.ads-gutter-holder .ads-gutter .gutter-ad-bottom {
  position: absolute;
  top: 1160px;
}
.ads-gutter-holder .ad-contents {
  max-width: 312px;
}
.ads-gutter-holder .ad-contents .ad.box {
  display: inline-block;
  padding: 0;
}
@media (max-width: 1623px) {
  .ads-gutter-holder {
    max-width: 1352px;
  }
}
@media (max-width: 1373px) {
  .ads-gutter-holder .ad-contents {
    max-width: 172px;
  }
  .ads-gutter-holder .ads-gutter {
    width: 172px;
  }
}
@media (max-width: 1233px) {
  .ads-gutter-holder {
    display: none;
  }
}
.ad-container-banner,
.ad-container-horizontal {
  background-color: #EDEDED;
  border: 0;
  text-align: center;
  border-bottom: 1px solid #DBDBDB;
}
.ad-container-banner .ad,
.ad-container-horizontal .ad {
  display: inline-block;
  border: none;
}
.adtype-90 {
  background-color: #EDEDED;
  border: 0;
  border-bottom: 1px solid #EDEDED;
  padding: 10px 0;
  text-align: center;
}
.ad-container-300x250,
.ad-container-300x250-conditional,
.ad-container-300x250-mobile {
  display: inline-block;
}
.adtype-300x250 {
  min-height: 250px;
}
.adtype-300x250-conditional {
  min-height: 250px;
}
@media (max-width: 999px) {
  .adtype-300x250-conditional {
    min-height: 0;
  }
}
.adtype-300x250-mobile {
  min-height: 0;
}
@media (max-width: 999px) {
  .adtype-300x250-mobile {
    min-height: 250px;
  }
}
.adtype-large300-conditional {
  min-height: 250px;
}
@media (max-width: 999px) {
  .adtype-large300-conditional {
    min-height: 0;
  }
}
.adtype-90 {
  min-height: 90px;
}
@media (max-width: 747px) {
  .adtype-90 {
    min-height: 50px;
  }
}
.adtype-horizontal {
  min-height: 90px;
}
@media (max-width: 747px) {
  .adtype-horizontal {
    min-height: 50px;
  }
}
.middle-ads {
  float: left;
  min-height: 250px;
  margin: 10px 30px 10px 0;
  text-align: center;
  width: 300px;
}
.middle-ads .ad-contents:first-of-type {
  float: left;
}
.middle-ads .ad-contents:nth-of-type(2) {
  float: right;
}
.bottom-ads {
  text-align: center;
  clear: both;
}
.bottom-ads .ad-contents {
  display: inline-block;
  vertical-align: top;
}
.bottom-ads .ad-contents:first-of-type {
  margin-right: 50px;
}
@media (max-width: 750px) {
  .bottom-ads .ad-contents:first-of-type {
    margin-right: 0;
  }
}
@media (max-width: 750px) {
  .bottom-ads .ad-contents {
    padding: 0 0 8px;
  }
}
.sidebar {
  float: right;
  margin-left: 10px;
  margin-bottom: 20px;
  z-index: 1000;
  width: 310px;
}
@media (max-width: 749px) {
  .sidebar {
    margin-left: 0;
    float: none;
  }
}
#whats-new-modal .modal-content {
  padding: 0;
}
#whats-new-modal .header {
  padding: 12px 20px 12px 70px;
  position: relative;
  z-index: 10;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.8), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
#whats-new-modal .header i {
  position: absolute;
  font-size: 50px;
  left: 5px;
  top: 15px;
  color: #00C4C9;
}
#whats-new-modal .header h3 {
  font-size: 35px;
  color: #00C4C9;
  margin: 0;
  padding: 0;
}
#whats-new-modal .header h4 {
  font-size: 13px;
  color: #878787;
  margin: 0;
  padding: 0;
}
#whats-new-modal .pages {
  background: #EDEDED;
  padding: 20px;
}
#whats-new-modal .pages .page {
  margin-bottom: 30px;
}
#whats-new-modal .pages .page h5 {
  color: #464646;
  font-size: 24px;
  margin: -5px 0 10px;
}
#whats-new-modal .pages .page .item {
  color: #464646;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}
#whats-new-modal .pages .page .item .item-body {
  padding: 8px 15px;
}
#whats-new-modal .pages .page .item > a {
  text-align: right;
  display: block;
  padding: 8px;
  margin-top: -5px;
  margin-bottom: -5px;
  color: #00C4C9;
}
#whats-new-modal .pages .page.features .item {
  margin-bottom: 20px;
  font-size: 15px;
}
#whats-new-modal .pages .page.features .item .item-title {
  font-size: 26px;
  padding: 8px 15px;
  border-bottom: 1px solid #DBDBDB;
}
#whats-new-modal .pages .page.content .item,
#whats-new-modal .pages .page.fixes .item {
  border-bottom: 1px solid #DBDBDB;
  font-size: 15px;
  border-radius: 0;
}
#whats-new-modal .pages .page.content .item .main-icon,
#whats-new-modal .pages .page.fixes .item .main-icon {
  font-size: 14px;
  width: 28px;
  height: 28px;
  display: inline-block;
  line-height: 28px;
  text-align: center;
  color: #fff;
  background: #00C4C9;
  border-radius: 30px;
  vertical-align: middle;
  margin-right: 12px;
}
#whats-new-modal .pages .page.content .item .main-icon.icon-worksheet,
#whats-new-modal .pages .page.fixes .item .main-icon.icon-worksheet {
  background: #BF9AC1;
}
#whats-new-modal .pages .page.content .item .main-icon.icon-star,
#whats-new-modal .pages .page.fixes .item .main-icon.icon-star {
  background: #44C5AC;
}
#whats-new-modal .pages .page.content .item .main-icon.icon-skill-builder,
#whats-new-modal .pages .page.fixes .item .main-icon.icon-skill-builder {
  background: #83667F;
}
#whats-new-modal .pages .page.content .item .main-icon.icon-lesson-plan,
#whats-new-modal .pages .page.fixes .item .main-icon.icon-lesson-plan {
  background: #854555;
}
#whats-new-modal .pages .page.content .item:first-of-type,
#whats-new-modal .pages .page.fixes .item:first-of-type {
  border-top: 1px solid #DBDBDB;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#whats-new-modal .pages .page.content .item:last-of-type,
#whats-new-modal .pages .page.fixes .item:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
#whats-new-modal .pages .page.content .item > a,
#whats-new-modal .pages .page.fixes .item > a {
  font-size: 13px;
}
#whats-new-modal .pages .page.fixes .item {
  position: relative;
}
#whats-new-modal .pages .page.fixes .item .main-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}
#whats-new-modal .pages .page.fixes .item .item-body {
  margin-left: 45px;
  padding: 8px 15px 8px 11px;
}
.tt-hint {
  visibility: hidden;
}
.search-form.form span.twitter-typeahead,
.nav-search-form span.twitter-typeahead {
  background: white;
  position: relative;
  display: inherit !important;
  width: 100%;
  border-radius: 30px;
}
.search-form.form .tt-menu,
.nav-search-form .tt-menu {
  /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  top: 35px !important;
  padding: 0 !important;
  max-height: 300px;
  overflow-y: auto;
  text-align: left;
  z-index: 10000 !important;
}
.search-form.form .tt-menu .tt-suggestion,
.nav-search-form .tt-menu .tt-suggestion,
.search-form.form .tt-menu .empty-results,
.nav-search-form .tt-menu .empty-results {
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
}
.search-form.form .tt-menu .tt-suggestion,
.nav-search-form .tt-menu .tt-suggestion {
  margin: 0;
}
.search-form.form .tt-menu .tt-suggestion:hover,
.nav-search-form .tt-menu .tt-suggestion:hover {
  cursor: pointer !important;
  background: #f7f7f7 !important;
}
.search-form.form .tt-menu .tt-suggestion.tt-cursor,
.nav-search-form .tt-menu .tt-suggestion.tt-cursor {
  /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
  color: #333;
  background-color: #f7f7f7;
}
.search-form.form .tt-menu .tt-suggestion p,
.nav-search-form .tt-menu .tt-suggestion p {
  margin: 0;
}
.bg-primary,
.bg-teal {
  background-color: #00C4C9;
  color: #fff;
}
.bg-interactive,
.bg-blue {
  background-color: #5672c4;
  color: #fff;
}
.bg-secondary,
.bg-peach {
  background-color: #ffded1;
  color: #464646;
}
.bg-cream {
  background-color: #faf5ed;
  color: #464646;
}
.bg-text-primary {
  background-color: #464646;
  color: #FFF;
}
.bg-dark-teal {
  background-color: #00b5ba;
  color: #FFF;
}
.bg-premium {
  background-color: #5672c4;
  color: #FFF;
}
.bg-black {
  background-color: #000;
  color: #fff;
}
.bg-dark-gray87 {
  background-color: #878787;
  color: #fff;
}
.bg-dark-gray9F {
  background-color: #9F9F9F;
  color: #fff;
}
.bg-grayC5 {
  background-color: #C5C5C5;
  color: #fff;
}
.bg-light-grayDB {
  background-color: #DBDBDB;
  color: #464646;
}
.bg-light-grayED {
  background-color: #EDEDED;
  color: #464646;
}
.bg-white {
  background-color: #FFF;
  color: #464646;
}
.bg-ecru {
  background-color: #fcfbf7;
  color: #464646;
}
/******old - delete if not used **************/
.bg-red {
  color: #fff;
}
.bg-yellow {
  color: #fff;
}
.bg-brown {
  color: #fff;
}
.bg-green {
  color: #fff;
}
.bg-purple {
  color: #fff;
}
.bg-gradient {
  color: #fff;
  background-color: #00b5ba;
}
.bg-gray2 {
  color: #fff;
}
.bg-gray4 {
  color: #fff;
}
.bg-gray6 {
  color: #fff;
}
.bg-gray8 {
  color: #fff;
}
.bg-grayC,
.bg-grayc {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.bg-worksheet {
  background-color: #BF9AC1;
  color: #fff;
}
.bg-game {
  background-color: #44C5AC;
  color: #fff;
}
.bg-workbook {
  background-color: #C9B36F;
  color: #fff;
}
.bg-activity {
  background-color: #EE9485;
  color: #fff;
}
.bg-lesson-plan {
  background-color: #854555;
  color: #fff;
}
.bg-science-project {
  background-color: #E97CA7;
  color: #fff;
}
.bg-song {
  background-color: #9AAB6C;
  color: #fff;
}
.bg-story {
  background-color: #98ADDA;
  color: #fff;
}
.bg-exercise {
  background-color: #83667F;
  color: #fff;
}
.bg-guided-lesson {
  background-color: #31708F;
  color: #fff;
}
.color-teal {
  color: #00C4C9;
}
.color-teal-light1 {
  color: #3FA9B2;
}
.color-teal-light2 {
  color: #6FBFC5;
}
.color-teal-light3 {
  color: #9FD4D9;
}
.color-teal-light4 {
  color: #CFEAEC;
}
.color-teal-light5 {
  color: #E7F4F5;
}
.color-teachers {
  color: #43BEAC;
}
.color-parents {
  color: #35C8DE;
}
.color-primary,
.color-orange {
  color: #00C4C9;
}
.color-white {
  color: #fff;
}
.color-worksheet {
  color: #BF9AC1;
}
.color-game {
  color: #44C5AC;
}
.color-workbook {
  color: #C9B36F;
}
.color-activity {
  color: #EE9485;
}
.color-lesson-plan {
  color: #854555;
}
.color-science-project {
  color: #E97CA7;
}
.color-song {
  color: #9AAB6C;
}
.color-story {
  color: #98ADDA;
}
.color-exercise {
  color: #83667F;
}
.color-guided-lesson {
  color: #31708F;
}
.layer-flat {
  border-radius: 4px;
  border: 1px solid #EDEDED;
  padding: 5px;
}
.layer-raised {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  padding: 5px;
}
.layer-dropdown {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  padding: 5px;
}
.layer-overlay {
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  padding: 5px;
}
.layer-modal {
  border-radius: 4px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22);
  padding: 5px;
}
.notifications-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
}
.notifications-container .notifications-icon {
  color: #464646;
  cursor: pointer;
  font-size: 22px;
  position: relative;
}
.notifications-container .num-notifications {
  background-color: #00C4C9;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  display: none;
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  margin-left: -13px;
  position: absolute;
  user-select: none;
  top: 34px;
  text-align: center;
}
.notifications {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  padding: 5px;
  padding: 0;
  background-color: white;
  display: none;
  margin-top: 12px;
  position: absolute;
  right: 0;
  user-select: none;
  -webkit-user-select: none;
  width: 446px;
  z-index: 5000040;
  color: #464646;
}
@media (max-width: 749px) {
  .notifications {
    margin: 0;
    text-align: left;
    width: 350px;
  }
}
.notifications .notification-header {
  font-size: 20px;
  line-height: 1.25;
  background: #FAFAFA;
  box-shadow: 0 1px 0 0 #EDEDED;
  padding: 16px 20px;
  position: relative;
  z-index: inherit;
  text-align: left;
}
@media (max-width: 750px) {
  .notifications .notification-header {
    font-size: 18px;
    line-height: 1.11;
  }
}
@media (max-width: 750px) and (min-width: 1000px) {
  .notifications .notification-header {
    line-height: 1.22;
  }
}
.notifications .notification {
  font-size: 14px;
  line-height: 1.25;
  font-family: TTNorms, Arial, sans-serif;
  font-weight: normal;
  border-bottom: 1px dotted #DBDBDB;
  line-height: 16px;
  padding: 12px 12px 12px 12px;
  position: relative;
  text-align: left;
}
@media (min-width: 1000px) {
  .notifications .notification {
    line-height: 1.375;
  }
}
@media (max-width: 749px) {
  .notifications .notification {
    line-height: 16px;
    padding: 12px 12px 10px 20px;
  }
}
.notifications .notification a {
  display: block;
  width: 90%;
  white-space: normal;
}
@media (max-width: 749px) {
  .notifications .notification a {
    width: 85%;
  }
}
.notifications .notification:last-of-type {
  border-bottom: 0;
}
.notifications .notification.notification-read a {
  color: #464646;
}
.notifications .notification.notification-unread {
  background-color: white;
}
.notifications .notification .notification-delete {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 30%;
  font-size: 10px;
}
.notifications .notification .notification-delete:before {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  color: #464646;
  background-color: #EDEDED;
  line-height: 29px;
}
.notifications .notification .notification-delete:hover:before {
  background-color: #e73225;
  color: #FFF;
}
.navbar .notifications-container {
  float: right;
  margin: 0 10px 0 0;
  text-align: center;
  width: 2em;
}
.navbar .notifications-container .notifications-icon {
  margin: 0;
}
@media print {
  .banner-notifications-container {
    display: none;
  }
}
.banner-notification {
  box-sizing: border-box;
  position: relative;
}
.messagestack {
  position: relative;
  margin: 0;
}
.messagestack > div {
  margin: 0 auto;
}
@media print {
  .messagestack > div {
    display: none !important;
  }
}
.messagestack > div ul {
  border: 1px solid transparent;
  border-radius: 0px;
  list-style: none;
  margin: 0;
  padding: 13px;
  position: relative;
}
.messagestack > div ul.success {
  background-color: #57b89d;
  border-color: #57b89d;
  color: #FFF;
}
.messagestack > div ul.error {
  background-color: #e73225;
  border-color: #e73225;
  color: #FFF;
}
.messagestack > div ul.notice {
  background-color: #5197d5;
  border-color: #5197d5;
  color: #FFF;
}
.messagestack > div ul a {
  color: #FFF;
  text-decoration: underline;
}
.messagestack > div ul a:hover {
  color: #FFF;
}
.messagestack > div ul li {
  box-sizing: border-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}
.messagestack > div ul li.extra-page-padding {
  padding-right: 100px;
}
@media (max-width: 1599px) {
  .messagestack > div ul li {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 999px) {
  .messagestack > div ul li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 359px) {
  .messagestack > div ul li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.messagestack > div ul li .message {
  display: inline;
  font-size: 18px;
  line-height: 14px;
  vertical-align: text-top;
}
.messagestack > div ul li i {
  font-size: 24px;
}
.messagestack > div ul li .close-message {
  cursor: pointer;
  /*float: right;
          padding-top: 7px;*/
  position: absolute;
  top: 35%;
  right: 3%;
}
.messagestack > div ul li .close-message .icon-cancel-light {
  font-size: 18px;
}
.flash-sale-banner {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.25;
  background-color: #00b5ba;
  text-align: center;
  color: #FFF;
  padding-top: 11px;
  padding-bottom: 11px;
  margin: 0;
}
@media (max-width: 1200px) {
  .flash-sale-banner {
    font-size: 18px;
    line-height: 1.11;
  }
}
@media (max-width: 1200px) and (min-width: 1000px) {
  .flash-sale-banner {
    line-height: 1.22;
  }
}
@media (max-width: 750px) {
  .flash-sale-banner {
    font-size: 18px;
    line-height: 1.11;
  }
}
@media (max-width: 750px) and (min-width: 1000px) {
  .flash-sale-banner {
    line-height: 1.22;
  }
}
@media (max-width: 360px) {
  .flash-sale-banner {
    font-size: 18px;
    line-height: 1.11;
  }
}
@media (max-width: 360px) and (min-width: 1000px) {
  .flash-sale-banner {
    line-height: 1.22;
  }
}
.flash-sale-banner.free-trial {
  padding: 0;
}
.flash-sale-banner.free-trial .flash-sale-msg.desktop {
  display: flex;
}
.flash-sale-banner.free-trial .flash-sale-msg.mobile {
  padding: 10px;
  display: none;
}
@media (max-width: 1000px) {
  .flash-sale-banner.free-trial {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .flash-sale-banner.free-trial img {
    display: none;
  }
}
@media (max-width: 850px) {
  .flash-sale-banner.free-trial .flash-sale-msg.desktop {
    display: none;
  }
  .flash-sale-banner.free-trial .flash-sale-msg.mobile {
    display: flex;
  }
}
.flash-sale-banner .flash-sale-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}
.flash-sale-banner .flash-sale-msg .btn {
  flex-shrink: 0;
  margin-left: 1em;
}
.bfcm-banner {
  background-color: #444;
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: cover;
}
.bfcm-banner .desktop {
  display: flex;
  align-items: center;
}
.bfcm-banner .mobile {
  display: none;
}
@media screen and (max-width: 1200px) {
  .bfcm-banner .desktop {
    display: none;
  }
  .bfcm-banner .mobile {
    display: flex;
  }
}
.bfcm-banner.black-friday {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Black-Friday-v1a.png");
}
@media screen and (max-width: 1200px) {
  .bfcm-banner.black-friday {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Black-Friday-bg-mobile.png");
  }
}
.bfcm-banner.cyber-monday {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Monday-Sale-bg.png");
}
@media screen and (max-width: 1200px) {
  .bfcm-banner.cyber-monday {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Monday-Sale-bg-mobile.png");
  }
}
.bfcm-banner.cyber-week {
  background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Week-bg.png");
}
@media screen and (max-width: 1200px) {
  .bfcm-banner.cyber-week {
    background-image: url("https://cdn.education.com/files/static/sales/bfcm-2021/Cyber-Week-bg-mobile.png");
  }
}
.black-friday-banner-mobile {
  background-color: #464646;
}
@media (min-width: 1201px) {
  .black-friday-banner-mobile {
    display: none;
  }
}
.cyber-monday-banner {
  background-color: #5672c4;
  height: 62px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .cyber-monday-banner {
    display: none;
  }
}
.cyber-monday-banner-mobile {
  background-color: #5672c4;
}
@media (min-width: 1201px) {
  .cyber-monday-banner-mobile {
    display: none;
  }
}
.cyber-week-banner {
  background-color: #5672c4;
  height: 62px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .cyber-week-banner {
    display: none;
  }
}
.cyber-week-banner-mobile {
  background-color: #5672c4;
}
@media (min-width: 1201px) {
  .cyber-week-banner-mobile {
    display: none;
  }
}
.countdown-clock {
  padding-left: 10px;
  height: 40px;
}
.countdown-clock .box {
  font-family: 'Bebas Neue' !important;
  margin: 0 8px;
  padding: 3px;
  position: relative;
  /*float: left;*/
  width: 12px;
  height: 14px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #FFF;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f25e82+2,f25e82+49,e55474+49,000000+51,f25e82+53,f25e82+100 */
  background: #f25e82;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f25e82 2%, #f25e82 49%, #e55474 49%, #000000 51%, #f25e82 53%, #f25e82 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f25e82 2%, #f25e82 49%, #e55474 49%, #000000 51%, #f25e82 53%, #f25e82 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f25e82 2%, #f25e82 49%, #e55474 49%, #000000 51%, #f25e82 53%, #f25e82 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25e82', endColorstr='#f25e82', GradientType=0);
  /* IE6-9 */
}
.countdown-clock small {
  display: block;
  clear: both;
  font-size: 8px;
  line-height: 12px;
  font-weight: 600;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  padding-top: 5px;
  font-stretch: ultra-condensed;
}
.countdown-clock #days,
.countdown-clock #hours,
.countdown-clock #minutes,
.countdown-clock #seconds {
  float: left;
  margin-top: 5px;
}
.net-promoter-score {
  bottom: -180px;
  opacity: 0;
  z-index: 999999;
  position: fixed;
  left: 0;
  right: 0;
  padding: 8px 5px 15px 5px;
  background: #ffffff;
  border-top: 1px solid #fff;
  text-align: center;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  transition: bottom 0.4s, opacity 0.4s;
}
.net-promoter-score p {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.275;
  font-weight: 400;
}
@media (max-width: 1200px) {
  .net-promoter-score p {
    font-size: 24px;
    line-height: 1.25;
  }
}
@media (max-width: 750px) {
  .net-promoter-score p {
    font-size: 22px;
    line-height: 1.25;
  }
}
@media (max-width: 360px) {
  .net-promoter-score p {
    font-size: 20px;
    line-height: 1.25;
  }
}
.net-promoter-score.open {
  bottom: 0;
  opacity: 1;
}
.net-promoter-score .detractor,
.net-promoter-score .passive,
.net-promoter-score .promoter {
  width: 65%;
  margin: 0 auto;
  display: none;
}
.net-promoter-score .detractor.visible,
.net-promoter-score .passive.visible,
.net-promoter-score .promoter.visible {
  display: block;
}
.net-promoter-score .detractor.subtext,
.net-promoter-score .passive.subtext,
.net-promoter-score .promoter.subtext {
  font-size: 14px;
  color: #999;
  font-weight: 200;
}
@media (max-width: 1200px) {
  .net-promoter-score .detractor,
  .net-promoter-score .passive,
  .net-promoter-score .promoter {
    width: 100%;
  }
}
.net-promoter-score textarea {
  border-radius: 6px;
  width: 65%;
  border: 1px solid #c5c5c5;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 16px;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
  outline-style: none;
}
.net-promoter-score textarea:hover {
  border: 1px solid #5672c4;
}
@media (max-width: 1200px) {
  .net-promoter-score textarea {
    width: 100%;
  }
}
.net-promoter-score .dismiss {
  position: absolute;
  top: 2px;
  right: 2px;
  color: #999;
  cursor: pointer;
  font-size: 20px;
}
.net-promoter-score .values {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}
.net-promoter-score .values .value {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #dbdbdb;
  border-radius: 30px;
  cursor: pointer;
}
@media (max-width: 479px) {
  .net-promoter-score .values .value {
    width: 24px;
    height: 24px;
  }
}
.net-promoter-score .values .value:hover {
  background: #5672c4;
}
.net-promoter-score .values .label {
  font-size: 13px;
  position: absolute;
  bottom: -15px;
}
.net-promoter-score .values .label.left {
  left: 0;
}
.net-promoter-score .values .label.right {
  right: 0;
}
@media print {
  .net-promoter-score {
    display: none !important;
  }
}
.gdpr-banner {
  position: fixed;
  bottom: 0;
  z-index: 999999;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  background-color: #6483df;
  margin-bottom: 0;
}
.gdpr-banner h3 {
  color: #fff;
  font-size: 20px;
  display: inline-block;
}
.gdpr-banner a {
  display: inline-block;
  vertical-align: 0;
  line-height: 20px;
  margin-left: 20px;
}
footer.unified {
  display: block;
  background-color: rgba(70, 70, 70, 0.8);
  color: #FFF;
}
footer.unified.gdpr {
  padding-bottom: 80px;
}
footer.unified #opt-out-modal-text-popup .modal-content h3,
footer.unified #opt-out-modal-text-popup .modal-content p {
  margin-bottom: 20px;
}
footer.unified a {
  color: #FFF;
}
footer.unified a:hover {
  color: #FFF;
}
footer.unified p {
  color: #FFF;
  font-size: 12px;
}
footer.unified #gdpr-opt-out-modal-text-popup p {
  color: #000;
  font-size: 16px;
}
@media print {
  footer.unified {
    display: none !important;
  }
}
footer.unified .link-groups {
  width: 100%;
  padding-bottom: 90px;
  justify-content: space-around;
}
footer.unified .link-groups > div {
  padding: 90px 33px 0 0;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  footer.unified .link-groups > div {
    padding-right: 25px;
  }
}
@media (min-width: 851px) {
  footer.unified .link-groups > div:last-child {
    padding-right: 0;
  }
}
footer.unified .link-groups > div .header {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 8px;
}
footer.unified .link-groups > div ul {
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
  font-size: 14px;
}
footer.unified .link-groups > div ul li {
  margin: 0;
  padding: 0 0 10px;
  line-height: 1.29em;
}
@media (max-width: 850px) {
  footer.unified .link-groups {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  footer.unified .link-groups > div {
    width: 50%;
    padding: 30px 20px 0;
    text-align: center;
  }
}
@media (max-width: 479px) {
  footer.unified .link-groups > div {
    width: 50%;
    padding: 30px 12px 0;
  }
  footer.unified .link-groups > div ul li {
    padding: 0 0 12px;
  }
}
footer.unified .footer-bottom {
  background-color: #464646;
  text-align: center;
  padding-top: 74px;
  padding-bottom: 74px;
}
footer.unified .footer-bottom .social-links {
  padding-bottom: 15px;
}
footer.unified .footer-bottom .social-links a {
  display: inline-block;
  background: #fff;
  color: #464646;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin: 0 3px;
}
footer.unified .footer-bottom .social-links a .ins-icon-wrapper {
  position: relative;
  top: 4px;
  left: 0px;
  width: 22.39px;
  height: 19px;
  fill: #464646;
}
@media (max-width: 599px) {
  footer.unified .footer-bottom .social-links {
    text-align: center;
  }
}
footer.unified .footer-bottom .copyright {
  padding-top: 10px;
  font-size: 14px;
}
@media (max-width: 599px) {
  footer.unified .footer-bottom .copyright {
    text-align: center;
  }
}
footer.unified .ixl-products {
  display: flex;
  height: 56px;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0 32px 0;
}
@media (max-width: 889px) {
  footer.unified .ixl-products {
    height: 85px;
    margin: 0 0 15px 0;
  }
}
@media (max-width: 780px) {
  footer.unified .ixl-products {
    display: none;
    margin: 0 0 30px 0;
  }
}
footer.unified .ixl-products .box-ixl-products {
  margin: 0;
  padding: 0px 17px 0;
  position: relative;
  white-space: normal;
  min-width: 0px;
  overflow-wrap: anywhere;
}
footer.unified .ixl-products .box-ixl-products h5 {
  font-size: 13px;
  margin: 0 0 5px 0;
  font-weight: 700;
}
footer.unified .ixl-products .box-ixl-products p {
  font-size: 10px;
  line-height: 1.25em;
  margin: auto;
  max-width: 105px;
}
footer.unified .ixl-products .box-divider {
  background-color: #999;
  height: 100%;
  width: 1px;
  flex-shrink: 0;
}
@media (min-width: 611px) {
  .modal#student-access {
    width: 96%;
    margin-left: -48%;
  }
}
@media (min-width: 850px) {
  .modal#student-access {
    width: 850px;
    margin-left: -425px;
  }
}
.modal#student-access .modal-content {
  padding: 0;
  position: relative;
}
.modal#student-access .modal-content .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.modal#student-access .modal-content .close-button i.x-close {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  overflow: hidden;
  cursor: pointer;
}
.modal#student-access .modal-content .close-button i.x-close:hover::before,
.modal#student-access .modal-content .close-button i.x-close:hover::after {
  background: #585858;
}
.modal#student-access .modal-content .close-button i.x-close::before,
.modal#student-access .modal-content .close-button i.x-close::after {
  height: 3px;
  margin-top: -2px;
  content: '';
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  background: #8b8b8b;
}
.modal#student-access .modal-content .close-button i.x-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal#student-access .modal-content .close-button i.x-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal#student-access .modal-content .main-menu {
  display: flex;
}
.modal#student-access .modal-content .main-menu .icon img {
  max-width: 130px;
}
.modal#student-access .modal-content .main-menu .title {
  font-size: 30px;
  max-width: 350px;
  margin: 13px auto 0;
}
.modal#student-access .modal-content .main-menu .button-container {
  position: absolute;
  bottom: 22%;
  right: 0;
  left: 0;
}
.modal#student-access .modal-content .main-menu .button-container a.btn {
  width: 230px;
  border-radius: 20px;
  padding: 9px;
  max-width: 100%;
}
@media (max-width: 500px) {
  .modal#student-access .modal-content .main-menu .button-container a.btn {
    width: 140px;
  }
}
.modal#student-access .modal-content .main-menu .launch-browser-container,
.modal#student-access .modal-content .main-menu .view-instructions-container {
  text-align: center;
  flex: 1 1 50%;
  padding: 90px 0 150px;
  position: relative;
}
.modal#student-access .modal-content .main-menu .view-instructions-container {
  background: #f2f2f2;
}
.modal#student-access .modal-content .device-instructions-container {
  display: none;
  text-align: left;
}
.modal#student-access .modal-content .device-instructions-container .instructions-contain-inside {
  display: flex;
}
@media (max-width: 450px) {
  .modal#student-access .modal-content .device-instructions-container .instructions-contain-inside {
    display: block;
  }
}
.modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar {
  flex: 1;
  padding: 15px 5px;
}
.modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar .back-button-container {
  color: #5672c4;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar .back-button-container .icon {
  height: 35px;
  width: 35px;
  border: 2px solid #5672c4;
  border-radius: 50%;
  position: relative;
  margin-right: 5px;
}
.modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar .back-button-container .icon i {
  font-size: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
}
@media (max-width: 850px) {
  .modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar .back-button-container .icon {
    margin-right: 0;
  }
  .modal#student-access .modal-content .device-instructions-container .instructions-contain-inside .left-bar .back-button-container span {
    display: none;
  }
}
.modal#student-access .modal-content .device-instructions-container .main-container {
  padding: 25px;
  background: #f2f2f2;
}
.modal#student-access .modal-content .device-instructions-container .main-container .main-title {
  font-size: 38px;
  margin-bottom: 30px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section {
  display: flex;
  margin: 15px 0;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .app-stores-links {
  display: flex;
  margin-top: 10px;
  text-align: center;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .app-stores-links .link {
  width: 150px;
  height: auto;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (max-width: 450px) {
  .modal#student-access .modal-content .device-instructions-container .main-container .section .login-types {
    width: 100%;
  }
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item.student-code a {
  display: flex;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item.student-code a .text {
  margin-right: 7px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item.student-code a .download-icon {
  font-size: 12px;
  height: 13px;
  border-bottom: 2px solid #2e8cca;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item .code {
  color: #F7931D;
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item .or-icon {
  height: 24px;
  width: 24px;
  background-color: #9FD4D9;
  border-radius: 50%;
  position: relative;
  margin: 0 10px;
  color: white;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item .or-icon .text {
  width: 100%;
  text-align: center;
}
@media (max-width: 767px) {
  .modal#student-access .modal-content .device-instructions-container .main-container .section .login-types {
    width: 300px;
    flex-wrap: wrap;
  }
  .modal#student-access .modal-content .device-instructions-container .main-container .section .login-types .item {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) and (max-width: 450px) {
  .modal#student-access .modal-content .device-instructions-container .main-container .section .login-types {
    width: 100%;
  }
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .section-icon {
  height: 38px;
  width: 38px;
  background: #00C4C9;
  border-radius: 25px;
  position: relative;
  flex: 0 0 38px;
  margin-right: 20px;
  font-weight: 600;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .section-icon span {
  color: white;
  font-size: 18px;
  position: absolute;
  top: 7px;
  left: 15px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .text .title {
  color: #00C4C9;
  font-size: 24px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .section .text .description {
  color: #000000;
  font-size: 16px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .close-button-container {
  text-align: center;
  margin-top: 30px;
}
.modal#student-access .modal-content .device-instructions-container .main-container .close-button-container a {
  width: 220px;
  font-size: 18px;
}
/* REDIRECT STYLES */
.redirect-container {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: 5000;
  /*really large number to be above everything*/
  background-color: #ffded1;
  transition: transform 250ms linear;
  box-shadow: 0px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
@media (max-width: 750px) {
  .redirect-container {
    height: 210px;
    text-align: left;
  }
}
@media (max-width: 600px) {
  .redirect-container {
    height: 263px;
    text-align: left;
  }
}
.redirect-container .cancel {
  position: absolute;
  right: 20px;
  top: 15px;
  display: block;
  color: #878787;
  z-index: 1;
  padding: 10px 10px;
}
.redirect-container .cancel:hover {
  cursor: pointer;
}
.redirect-container .content {
  display: inline-block;
  max-width: 1050px;
}
.redirect-container .content .logo {
  display: inline-block;
  width: 220px;
  height: 72.5px;
  margin: 30px 20px 0 0;
  vertical-align: top;
}
@media (max-width: 1000px) {
  .redirect-container .content .logo {
    margin: 15px 20px 0 20px;
    width: 180px;
    height: 59.3px;
  }
}
@media (max-width: 750px) {
  .redirect-container .content .logo {
    display: block;
    margin: 15px 0 0 20px;
  }
}
.redirect-container .content .text {
  width: calc(100% - 430px);
  display: inline-block;
  margin: 20px 0;
  text-align: left;
}
@media (max-width: 1000px) {
  .redirect-container .content .text {
    margin: 5px 0;
  }
}
.redirect-container .content .text h4 {
  margin: 10px 0 10px 0;
  font-size: 24px;
  line-height: 1.25;
}
.redirect-container .content .text .redirected-from {
  font-weight: bold;
}
@media (max-width: 1200px) {
  .redirect-container .content .text {
    width: calc(100% - 460px);
  }
}
@media (max-width: 750px) {
  .redirect-container .content .text {
    width: 60%;
    min-width: 280px;
    margin: 10px 10px 0 20px;
  }
}
@media (max-width: 600px) {
  .redirect-container .content .text {
    width: calc(100% - 30px);
    height: 115px;
    margin: 0 15px;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
  }
  .redirect-container .content .text h4 {
    margin: 10px 0 5px 0;
    font-size: 20px;
    line-height: 1.4;
  }
}
.redirect-container .content .right {
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 0;
  text-align: center;
  width: 180px;
  height: 140px;
}
@media (max-width: 1200px) {
  .redirect-container .content .right {
    margin-right: 30px;
  }
}
@media (max-width: 600px) {
  .redirect-container .content .right {
    margin: 15px auto 0 auto;
    width: 100%;
    float: none;
    display: block;
  }
}
.redirect-container .content .right .btn {
  width: 180px;
  display: block;
  position: absolute;
  bottom: 25px;
  z-index: 2;
}
@media (max-width: 600px) {
  .redirect-container .content .right .btn {
    position: static;
    width: calc(100% - 30px);
    margin: 0 15px;
  }
}
.redirect-container .content .right .floyd {
  width: 71px;
  height: 93px;
  position: absolute;
  bottom: 75px;
  left: 55px;
  z-index: 1;
  transform-origin: 50% 100%;
  animation: kki_floyd-enter 600ms;
  animation-fill-mode: forwards;
}
@media (max-width: 600px) {
  .redirect-container .content .right .floyd {
    display: none;
  }
}
.redirect-container .content .right .floyd-shadow {
  width: 66px;
  height: 6.5px;
  position: absolute;
  bottom: 73px;
  left: 57px;
  z-index: 0;
  transform-origin: 50% 100%;
  animation: kki_floyd-shadow-enter 600ms;
  animation-fill-mode: forwards;
}
@media (max-width: 600px) {
  .redirect-container .content .right .floyd-shadow {
    display: none;
  }
}
@keyframes kki_floyd-enter {
  0% {
    transform: scale(0.3) translate(35px, 45px);
    opacity: 0;
  }
  30% {
    transform: scale(0.3) translate(35px, 45px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  92% {
    transform: scale(1.15) translate(0px, 0px);
  }
  100% {
    transform: scale(1) translate(0px, 0px);
  }
}
@keyframes kki_floyd-shadow-enter {
  0% {
    transform: scale(0.3) translate(35px, 45px);
    opacity: 0;
  }
  30% {
    transform: scale(0.3) translate(35px, 45px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0px, 0px);
    opacity: 1;
  }
}
.redirect-container.hidden {
  transform: translateY(150%);
  pointer-events: none;
}
.content-result .collectible-content-item {
  position: absolute;
  top: 190px;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 20px;
  padding: 8px 5px;
  text-align: right;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 10;
}
.content-result .collectible-content-item .btn-round {
  padding: 0;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 20px;
  color: #878787;
  box-sizing: border-box;
  pointer-events: auto;
  cursor: pointer;
}
.content-result .collectible-content-item .btn-round:hover {
  color: #878787;
}
.content-result .collectible-content-item .btn-round .icon-bookmark {
  display: inline;
  margin-right: -1px;
}
.content-result .collectible-content-item .btn-round .icon-bookmark-filled {
  display: none;
  color: #00C4C9;
  margin-right: -1px;
}
.content-result .collectible-content-item .btn-round.selected .icon-bookmark {
  display: none;
}
.content-result .collectible-content-item .btn-round.selected .icon-bookmark-filled {
  display: inline;
}
.content-result:hover .collectible-content-item {
  opacity: 1;
}
.collectible-content-item.inline {
  font-weight: 600;
}
.collectible-content-item.inline .btn-collect .icon-bookmark {
  display: inline;
  margin-right: -1px;
}
.collectible-content-item.inline .btn-collect .icon-bookmark-filled {
  display: none;
  margin-right: -1px;
}
.collectible-content-item.inline .btn-collect span.add-to-collection {
  display: inline;
}
.collectible-content-item.inline .btn-collect span.added-to-collection {
  display: none;
}
.collectible-content-item.inline .btn-collect.selected .icon-bookmark {
  display: none;
}
.collectible-content-item.inline .btn-collect.selected .icon-bookmark-filled {
  display: inline;
}
.collectible-content-item.inline .btn-collect.selected span.add-to-collection {
  display: none;
}
.collectible-content-item.inline .btn-collect.selected span.added-to-collection {
  display: inline;
}
.collectible-content-item.inline .btn-collect:hover {
  text-decoration: none;
}
.collectible-content-item.inline .btn-collect:hover span {
  text-decoration: underline;
}
.collectible-content-item.inline.disabled {
  pointer-events: none;
}
.collectible-content-item.inline.disabled a {
  color: #999;
}
/**
  Critical styles necessary for the custom collections modals to prevent flash on load
 */
#collections .choose-collection,
#collections .new-collection {
  transform: translateY(400px);
  opacity: 0;
  transition: transform 250ms, opacity 250ms, height 250ms, background 250ms;
}
#collections .collection {
  transform: translateY(55px);
  opacity: 0;
  transition: transform 250ms, opacity 250ms;
}
#collections .overlay-back {
  background: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 250ms;
}
