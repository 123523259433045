@import (reference) "colors.less";
@import (reference) "screen-sizes.less";
@import (reference) "color-classes.less";
@import (reference) "typography.less";
@import (reference) "flexbox.less";

.gdpr-banner {
  position: fixed;
  bottom: 0;
  z-index: 999999;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  background-color: @color-blue-light;
  margin-bottom: 0;
  h3 {
    color: #fff;
    font-size: 20px;
    display: inline-block;
  }
  a {
    display: inline-block;
    vertical-align: 0;
    line-height: 20px;
    margin-left: 20px;
  }
}

footer.unified {
  display: block;
  background-color: fade(@color-gray-primary, 80%);

  &.gdpr {
    padding-bottom: 80px;
  }

  #opt-out-modal-text-popup .modal-content {
    h3, p {
      margin-bottom: 20px;
    }
    p {
      //font-size: 15px;
    }
    a {
      //font-size: 18px;
    }
  }

  color: @color-white;
  a {
    color: @color-white;
    &:hover {
      color: @color-white;
    }
  }

  p {
    color: @color-white;
    font-size: 12px;
  }

  #gdpr-opt-out-modal-text-popup {
    p {
      color: @color-black;
      font-size: 16px;
    }
  }

  @media print {
    display: none !important;
  }

  .link-groups {
    width: 100%;
    padding-bottom: 90px;
    justify-content: space-around;

    > div {
      padding: 90px 33px 0 0;
      box-sizing: border-box;

      @media (max-width: 1024px) {
        padding-right: 25px;
      }

      &:last-child {
        @media (min-width: 851px) {
          padding-right: 0;
        }
      }

      .header {
        .font-primary-bold;
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 8px;

      }
      ul {
        list-style-type: none;
        margin-top: 15px;
        padding: 0;
        font-size: 14px;

        li {
          margin: 0;
          padding: 0 0 10px;
          line-height: 1.29em;
        }
      }
    }

    @media (max-width: 850px) {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 25px;
      > div {
        width: 50%;
        padding: 30px 20px 0;
        text-align: center;
      }
    }
    @media (max-width: 479px) {
      > div {
        width: 50%;
        padding: 30px 12px 0;
        ul li {
          padding: 0 0 12px;
        }
      }
    }
  }
  .footer-bottom {
    background-color: @color-gray-primary;
    text-align: center;
    padding-top: 74px;
    padding-bottom: 74px;
    .social-links {
      padding-bottom: 15px;

      a {
        display: inline-block;
        background: #fff;
        color: @color-gray-primary;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin: 0 3px;

        .ins-icon-wrapper {
          position: relative;
          top: 4px;
          left: 0px;
          width: 22.39px;
          height: 19px;
          fill: #464646;
        }
      }
      
      @media (max-width: 599px) {
        text-align: center;
      }
    }
    .copyright {
      padding-top: 10px;
      font-size: 14px;

      @media (max-width: 599px) {
        text-align: center;
      }
    }
  }

  .ixl-products {
    display: flex;
    height: 56px;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0 32px 0;

    @media (max-width: 889px) {
      height: 85px;
      margin: 0 0 15px 0;
    }

    @media (max-width: 780px) {
      display: none;
      margin: 0 0 30px 0;
    }

    .box-ixl-products {
      margin: 0;
      padding: 0px 17px 0;
      position: relative;
      white-space: normal;
      min-width: 0px;
      overflow-wrap: anywhere;
      h5 {
        font-size: 13px;
        margin: 0 0 5px 0;
        font-weight:700;
      }

      p {
        font-size: 10px;
        line-height: 1.25em;
        margin: auto;
        max-width: 105px;
      }
    }

    .box-divider {
      background-color: #999;
      height: 100%;
      width: 1px;
      flex-shrink: 0;
    }
  }
}

.modal#student-access {

  @media (min-width: 611px) {
    width: 96%;
    margin-left: -48%;
  }
  @media (min-width: 850px) {
    width: 850px;
    margin-left: -425px;
  }

  .modal-content {
    padding: 0;
    position: relative;

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;

      i.x-close {
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          &::before, &::after {
            background: #585858;
          }
        }

        &::before, &::after {
          height: 3px;
          margin-top: -2px;

          content: '';
          position: absolute;
          width: 100%;
          top: 50%;
          left: 0;
          background: #8b8b8b;
        }

        &::before {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &::after {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }

    .main-menu {
      display: flex;

      .icon img {
        max-width: 130px;
      }

      .title {
        font-size: 30px;
        max-width: 350px;
        margin: 13px auto 0;
      }

      .button-container {
        position: absolute;
        bottom: 22%;
        right: 0;
        left: 0;

        a.btn {
          width: 230px;
          border-radius: 20px;
          padding: 9px;
          max-width: 100%;

          @media (max-width: 500px) {
            width: 140px;
          }
        }
      }

      .launch-browser-container, .view-instructions-container {
        text-align: center;
        flex: 1 1 50%;
        padding: 90px 0 150px;
        position: relative;
      }

      .view-instructions-container {
        background: #f2f2f2;
      }
    }

    .device-instructions-container {
      display: none;
      text-align: left;

      .instructions-contain-inside {
        display: flex;

        @media (max-width: 450px) {
          display: block;
        }
        .left-bar {
          flex: 1;
          padding: 15px 5px;

          .back-button-container{
            color: @color-interactive;
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon {
              height: 35px;
              width: 35px;
              border: 2px solid @color-interactive;
              border-radius: 50%;
              position: relative;
              margin-right: 5px;

              i {
                font-size: 20px;
                position: absolute;
                top: 4px;
                left: 4px;

              }
            }
          }

          @media (max-width: 850px) {
            .back-button-container {
              .icon {
                margin-right: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }

      .main-container {
        padding: 25px;
        background: #f2f2f2;

        .main-title {
          font-size: 38px;
          margin-bottom: 30px;
        }

        .section {
          display: flex;
          margin: 15px 0;

          .app-stores-links {
            display: flex;
            margin-top: 10px;
            text-align:center;

            .link {
              width: 150px;
              height:auto;
            }
          }

          .login-types {
            display: flex;
            align-items: center;
            margin-top: 10px;

            @media (max-width: 450px) {
              width: 100%;
            }

            .item {

              &.student-code {
                a {
                  display: flex;

                  .text {
                    margin-right: 7px;
                  }

                  .download-icon {
                    font-size: 12px;
                    height: 13px;
                    border-bottom: 2px solid #2e8cca;
                  }
                }
              }

              .code {
                color: #F7931D;
                .font-primary-bold;
              }

              .or-icon {
                height: 24px;
                width: 24px;
                background-color: #9FD4D9;
                border-radius: 50%;
                position: relative;
                margin: 0 10px;
                color: white;

                .text {
                  width: 100%;
                  text-align: center;
                }
              }
            }

            @media (max-width: 767px) {
              width: 300px;
              flex-wrap: wrap;

              @media (max-width: 450px) {
                width: 100%;
              }

              .item {
                margin-bottom: 10px;
              }
            }
          }

          .section-icon {
            height: 38px;
            width: 38px;
            background: @color-primary;
            border-radius: 25px;
            position: relative;
            flex: 0 0 38px;
            margin-right:20px;
            font-weight: 600;

            span {
              color: white;
              font-size: 18px;
              position: absolute;
              top: 7px;
              left: 15px;
            }
          }

          .text {
            .title {
              color: @color-primary;
              font-size: 24px;
            }

            .description {
              color: #000000;
              font-size: 16px;
            }
          }
        }

        .close-button-container {
          text-align: center;
          margin-top: 30px;

          a {
            width: 220px;
            font-size: 18px;
          }
        }
      }
    }
  }
}



/* REDIRECT STYLES */

.redirect-container {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: 5000; /*really large number to be above everything*/
  background-color: @color-peach;
  transition: transform 250ms linear;
  box-shadow: 0px -2px 20px 2px rgba(0,0,0,0.1);

  @media (max-width: @screen-tablet) {
    height: 210px;
    text-align: left;
  }
  @media (max-width: 600px) {
    height: 263px;
    text-align: left;
  }

  .cancel {
    position: absolute;
    right: 20px;
    top: 15px;
    display: block;
    color: @color-dark-gray87;
    z-index: 1;
    padding: 10px 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .content {
    display: inline-block;
    max-width: 1050px;

    .logo {
      display: inline-block;
      width: 220px;
      height: 72.5px;
      margin: 30px 20px 0 0;
      vertical-align: top;
      @media (max-width: @screen-tablet-lg) {
        margin: 15px 20px 0 20px;
        width: 180px;
        height: 59.3px;
      }
      @media (max-width: @screen-tablet) {
        display: block;
        margin: 15px 0 0 20px;
      }
    }
    .text {
      width: ~"calc(100% - 430px)";
      display: inline-block;
      margin: 20px 0;
      text-align: left;
      @media (max-width: @screen-tablet-lg) {
        margin: 5px 0;
      }
      h4 {
        margin: 10px 0 10px 0;
        font-size: 24px;
        line-height: 1.25;
      }
      .redirected-from {
        font-weight: bold;
      }
      @media (max-width: @screen-desktop) {
        width: ~"calc(100% - 460px)";
      }
      @media (max-width: @screen-tablet) {
        width: 60%;
        min-width: 280px;
        margin: 10px 10px 0 20px;
      }
      @media (max-width: 600px) {
        width: ~"calc(100% - 30px)";
        height: 115px;
        margin: 0 15px;
        font-size: 14px;
        line-height: 24px;
        box-sizing: border-box;
        h4 {
          margin: 10px 0 5px 0;
          font-size: 20px;
          line-height: 1.4;
        }
      }
    }
    .right {
      display: inline-block;
      position: relative;
      float: right;
      margin-right: 0;
      text-align: center;
      width: 180px;
      height: 140px;
      @media (max-width: @screen-desktop) {
        margin-right: 30px;
      }
      @media (max-width: 600px) {
        margin: 15px auto 0 auto;
        width: 100%;
        float: none;
        display: block;
      }

      .btn {
        width: 180px;
        display: block;
        position: absolute;
        bottom: 25px;
        z-index: 2;
        @media (max-width: 600px) {
          position: static;
          width: ~"calc(100% - 30px)";
          margin: 0 15px;
        }
      }

      .floyd {
        width: 71px;
        height: 93px;
        position: absolute;
        bottom: 75px;
        left: 55px;
        z-index: 1;
        transform-origin: 50% 100%;
        animation: kki_floyd-enter 600ms;
        animation-fill-mode: forwards;
        //transform: scale(0.3) translate(15px, 15px);
        //opacity: 0;
        @media (max-width: 600px) {
          display: none;
        }
      }
      .floyd-shadow {
        width: 66px;
        height: 6.5px;
        position: absolute;
        bottom: 73px;
        left: 57px;
        z-index: 0;
        transform-origin: 50% 100%;
        animation: kki_floyd-shadow-enter 600ms;
        animation-fill-mode: forwards;
        //transform: scale(0.3) translate(15px, 15px);
        //opacity: 0;
        @media (max-width: 600px) {
          display: none;
        }
      }
      @keyframes kki_floyd-enter {
        0%    { transform: scale(0.3) translate(35px, 45px); opacity: 0; }
        30%   { transform: scale(0.3) translate(35px, 45px); opacity: 0; }
        50%   { opacity: 1; }
        92%   { transform: scale(1.15) translate(0px, 0px); }
        100%  { transform: scale(1) translate(0px, 0px); }
      }
      @keyframes kki_floyd-shadow-enter {
        0%    { transform: scale(0.3) translate(35px, 45px); opacity: 0; }
        30%   { transform: scale(0.3) translate(35px, 45px); opacity: 0; }
        50%   { opacity: 0; }
        100%  { transform: scale(1) translate(0px, 0px); opacity: 1; }
      }
    }

  }

  &.hidden {
    transform: translateY(150%);
    pointer-events: none;
  }

}




